// Registration with email
export const EVENT_REG_METHOD_MAIL = "email";
// Registration with phone
export const EVENT_REG_METHOD_PHONE = "phone";
// Registration with social_google
export const EVENT_REG_METHOD_GOOGLE = "social_google";
// Registration with social_google
export const EVENT_REG_METHOD_COR = "corereg";

export const GTM_AUTO = "auto_event_trigger";

export const GTM_SEX_MALE = "male";
export const GTM_SEX_FEMALE = "female";

export const GTM_REGISTRATION = {
  autoEventCategory: "Registration",
  autoEventAction: "Successful Registration",
  autoEventLabel: EVENT_REG_METHOD_MAIL,
  userId: null,
};

export const GTM_REGISTRATION_FAIL = {
  autoEventCategory: "Registration",
  autoEventAction: "Registration Fail",
  autoEventLabel: EVENT_REG_METHOD_MAIL,
};

export const GTM_LOGIN = {
  autoEventCategory: "Login",
  autoEventAction: "Successful Login",
  autoEventLabel: null,
  userId: null,
};

export const GTM_VIEW_USER = {
  autoEventCategory: "Woman Profile",
  autoEventAction: "View profile",
};

export const GTM_VIEW_USER_PHOTO = {
  autoEventCategory: "Woman Profile",
  autoEventAction: "View photo",
};

export const GTM_VIEW_USER_VIDEO = {
  autoEventCategory: "Woman Profile",
  autoEventAction: "View video",
};

export const GTM_LIKE = {
  autoEventCategory: "Woman Profile",
  autoEventAction: "Like Profile",
};

export const GTM_FAVORITE = {
  autoEventCategory: "Woman Profile",
  autoEventAction: "Add to Favorites",
};

export const GTM_DEL_FAVORITE = {
  autoEventCategory: "Woman Profile",
  autoEventAction: "Remove from Favorites",
};

export const GTM_OPEN_CHAT = {
  autoEventCategory: "Chat",
  autoEventAction: "Open",
};

export const GTM_CHAT_MSG = {
  autoEventCategory: "Chat",
  autoEventAction: "Message sent",
};

export const GTM_CHAT_MSG_FAIL = {
  autoEventCategory: "Chat",
  autoEventAction: "Message fail",
};

export const GTM_CHAT_PHOTO = {
  autoEventCategory: "Chat",
  autoEventAction: "Photo sent",
};

export const GTM_CHAT_PHOTO_FAIL = {
  autoEventCategory: "Chat",
  autoEventAction: "Photo fail",
};

export const GTM_CHAT_VIDEO = {
  autoEventCategory: "Chat",
  autoEventAction: "Video sent",
};

export const GTM_CHAT_VIDEO_FAIL = {
  autoEventCategory: "Chat",
  autoEventAction: "Video fail",
};

export const GTM_MAIL_SEND = {
  autoEventCategory: "Chat",
  autoEventAction: "Letter sent",
};

export const GTM_MAIL_SEND_FAIL = {
  autoEventCategory: "Chat",
  autoEventAction: "Letter fail",
};

export const GTM_MAIL_OPEN = {
  autoEventCategory: "Chat",
  autoEventAction: "Letter open",
};

// export const GTM_MAIL_PHOTO_VIEW = {
//   'autoEventCategory': 'Chat',
//   'autoEventAction': 'Letter photo open',
// };
//
// export const GTM_MAIL_PHOTO_VIEW_FAIL = {
//   'autoEventCategory': 'Chat',
//   'autoEventAction': 'Letter photo fail',
// };

export const GTM_STICKER_SEND = {
  autoEventCategory: "Chat",
  autoEventAction: "Sticker sent",
};

export const GTM_STICKER_SEND_FAIL = {
  autoEventCategory: "Chat",
  autoEventAction: "Sticker fail",
};

export const GTM_LOGOUT = {
  autoEventCategory: "Logout",
  autoEventAction: "Successful Logout",
};

export const GTM_WIZARD_FINISH = {
  autoEventCategory: "Funnel",
  autoEventAction: "Successful Funnel",
};

export const GTM_MAIL_CONFIRM = {
  autoEventCategory: "Confirm",
  autoEventAction: "Successful Confirm",
};

export const GTM_GO_TO_INBOX = {
  autoEventCategory: "Inbox",
  autoEventAction: "Go to Inbox",
};

export const GTM_CHAT_FIRST = {
  autoEventCategory: "Chat",
  autoEventAction: "First Message",
};

export const GTM_SUCCESS_PURCHASE = {
  autoEventCategory: "Payment",
  autoEventAction: "Payment Successful",
  transaction_id: null,
  value: null,
};

export const GTM_FAILED_PURCHASE = {
  autoEventCategory: "Payment",
  autoEventAction: "Payment Fail",
};

export const GTM_SUBSCRIBE_ON_STREAM = {
  autoEventCategory: "video_live_stream",
  autoEventAction: "watch",
};
