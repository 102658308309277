import { createSlice } from "@reduxjs/toolkit";

import { GALLERY_SHOW_ENABLED } from "shared/constants";

const initialState = {
  first_name: {
    value: "",
    error: false,
    message: "",
  },
  residence_country: {
    value: "",
    error: false,
    message: "",
  },
  residence_city: {
    value: "",
    error: false,
    message: "",
  },
  birthday: {
    value: "",
    error: false,
    message: "",
  },
  month: {
    value: "",
    error: false,
    message: "",
  },
  day: {
    value: "",
    error: false,
    message: "",
  },
  year: {
    value: "",
    error: false,
    message: "",
  },
  body_type: {
    value: "",
    error: false,
    message: "",
  },
  gallery_show: {
    value: GALLERY_SHOW_ENABLED,
    error: false,
    message: "",
  },
  height: {
    value: "",
    error: false,
    message: "",
  },
  weight: {
    value: "",
    error: false,
    message: "",
  },
  marital_status: {
    value: "",
    error: false,
    message: "",
  },
  children: {
    value: "",
    error: false,
    message: "",
  },
  more_children: {
    value: "",
    error: false,
    message: "",
  },
  religion: {
    value: "",
    error: false,
    message: "",
  },
  drink: {
    value: "",
    error: false,
    message: "",
  },
  smoke: {
    value: "",
    error: false,
    message: "",
  },
  education_level: {
    value: "",
    error: false,
    message: "",
  },
  occupation: {
    value: "",
    error: false,
    message: "",
  },
  bio: {
    value: "",
    error: false,
    message: "",
  },
  error: {
    error: false,
    message: "",
  },
  send: false,
  valid: true,
  uploaded: false,
};

const profileUpdateSlice = createSlice({
  name: "profile/update",
  initialState,
  reducers: {},
});

export default profileUpdateSlice.reducer;
