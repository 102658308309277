import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  halloween: { isNotificationShowed: false },
  blackFriday: { isNotificationShowed: true },
  isNotificationEnabled: false,
};

const holidayEventSlice = createSlice({
  name: "holidayEvent",
  initialState,
  reducers: {
    setHalloweenNotificationView: (state, { payload }) => {
      state.halloween.isNotificationShowed = payload;
    },
    setBlackFridayNotificationView: (state, { payload }) => {
      state.blackFriday.isNotificationShowed = payload;
    },
    setHolidayNotificationEnabled: (state, { payload }) => {
      state.isNotificationEnabled = payload;
    },
  },
});
export const {
  setHalloweenNotificationView,
  setBlackFridayNotificationView,
  setHolidayNotificationEnabled,
} = holidayEventSlice.actions;

export default holidayEventSlice.reducer;
