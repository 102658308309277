import { createSlice } from "@reduxjs/toolkit";

import { ACTION_READ, ACTION_UNREAD } from "shared/constants/actiity";

export const initialState = {
  items: [],
  token: "",
  uploaded: false,
  send: false,
  error: {
    error: false,
    errorCode: null,
    message: "",
  },
  blur_notification: null,
};

const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    clearActivities: (state) => {
      return {
        ...state,
        initialState,
      };
    },
    setActivities: (state, { payload }) => {
      return {
        ...state,
        items: payload,
        uploaded: true,
        send: false,
      };
    },
    setBlurNotification: (state, { payload }) => {
      return {
        ...state,
        blur_notification: payload,
        uploaded: true,
        send: false,
      };
    },
    toggleSend: (state, { payload }) => {
      state.send = payload;
    },

    sendActivityRequest: (state, { payload }) => {
      return {
        ...state,
        send: payload,
      };
    },

    responseErrorActivity: (state, { payload }) => {
      return {
        ...state,
        send: false,
        error: {
          error: true,
          errorCode: payload.status,
          message: payload.statusText,
        },
      };
    },

    setReadActivities: (state) => {
      for (const [index, item] of state.items.entries()) {
        if (item.status === ACTION_UNREAD) {
          state.items[index].status = ACTION_READ;
        }
      }
      return state;
    },
    setReadActivity: (state, { payload }) => {
      for (const [index, item] of state.items.entries()) {
        if (item.uid === payload && item.status === ACTION_UNREAD) {
          state.items[index].status = ACTION_READ;
          break;
        }
      }
      return state;
    },
  },
});

export const {
  setActivities,
  clearActivities,
  toggleSend,
  setReadActivities,
  setReadActivity,
  setBlurNotification,
} = activitySlice.actions;

export default activitySlice.reducer;
