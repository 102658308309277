import i18next from "i18next";

import {
  IMAGE_PUBLIC,
  PROFILE_MEDIA_STATUS_ACTIVE,
  TYPE_PHOTO,
  TYPE_VIDEO,
} from "shared/constants";
import {
  getSizeInMb,
  UPLOAD_IMAGE_FILE_FORMATS,
  UPLOAD_MAX_IMAGE_FILE_SIZE,
  UPLOAD_MAX_VIDEO_FILE_SIZE,
  UPLOAD_VIDEO_EXTENSION,
  UPLOAD_VIDEO_FILE_FORMATS,
} from "shared/constants/files";

/**
 * Validate files before upload to api
 * @param {File} file File object
 * @param {Array} acceptExtension List of available file extensions
 * @return {{message: null, fileType: null, status: boolean}}
 */
export const uploadFileValidator = (file, acceptExtension) => {
  const result = {
    status: true,
    message: null,
    fileType: null,
  };
  let { size, type, name } = file;
  let fileType = null;

  if (!size || !type || !name) {
    result.status = false;
    result.message = i18next.t("common:files.error.valid");
    return result;
  }

  if (name.length > 20) {
    name = name.substring(0, 20) + "...";
  }

  if (UPLOAD_VIDEO_FILE_FORMATS.indexOf(type) >= 0) {
    fileType = TYPE_VIDEO;
  }

  if (UPLOAD_IMAGE_FILE_FORMATS.indexOf(type) >= 0) {
    fileType = TYPE_PHOTO;
  }

  if (fileType === null) {
    result.status = false;
    result.message =
      i18next.t("common:files.error.format") + acceptExtension.join(", ");
    return result;
  }

  if (fileType === TYPE_VIDEO) {
    const extension = file.name.split(".");

    if (
      extension.length <= 0 ||
      UPLOAD_VIDEO_EXTENSION.indexOf(
        extension[extension.length - 1].toLowerCase()
      ) < 0
    ) {
      result.status = false;
      result.message = `${name} - ${i18next.t(
        "common:files.error.extension"
      )}${UPLOAD_VIDEO_EXTENSION.join(", ")}`;
      return result;
    }

    if (size > UPLOAD_MAX_VIDEO_FILE_SIZE) {
      result.status = false;
      result.message = `${name} - ${i18next.t("common:files.error.video.size")}
                ${getSizeInMb(UPLOAD_MAX_VIDEO_FILE_SIZE)}Mb`;
      return result;
    }
  } else {
    if (size > UPLOAD_MAX_IMAGE_FILE_SIZE) {
      result.status = false;
      result.message = `${name} - ${i18next.t("common:files.error.image.size")}
                ${getSizeInMb(UPLOAD_MAX_IMAGE_FILE_SIZE)}Mb`;
      return result;
    }
  }

  result.fileType = fileType;
  return result;
};

/**
 * Get profile temporary media object
 * @param {number|string} uid Identity of file
 * @param {number} type Type of file
 * @return {{preview: null, uid, thumbnail: null, private: number, src: null, uploaded: boolean, placeholder: null, webp: boolean, type, send: boolean, empty: boolean, status: number}}
 */
export const getTemporaryProfileMedia = ({ uid, type }) => {
  return {
    uid,
    type,
    placeholder: null,
    thumbnail: null,
    preview: null,
    src: null,
    empty: true,
    private: IMAGE_PUBLIC,
    status: PROFILE_MEDIA_STATUS_ACTIVE,
    webp: false,
    uploaded: false,
    send: false,
    error: {
      status: false,
      message: null,
    },
  };
};
