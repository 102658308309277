export const IS_FOLLOWING = 1;
export const IS_ALL_POSTS = 0;

export const IS_LIKED = 1;
export const IS_DISLIKED = 2;

export const SET_REACTION = 1;
export const UNSET_REACTION = 2;

export const TYPE_LIKE = 1;
export const TYPE_LOVE = 2;
export const TYPE_FIRE = 3;
export const TYPE_KISS = 4;
export const TYPE_LAUGHTER = 5;
export const TYPE_SADNESS = 6;
export const REACTIONS = [
  {
    name: "like",
    reaction: "is_liked",
    type: TYPE_LIKE,
  },
  {
    name: "love",
    reaction: "is_loved",
    type: TYPE_LOVE,
  },
  {
    name: "fire",
    reaction: "is_fired",
    type: TYPE_FIRE,
  },
  {
    name: "kiss",
    reaction: "is_kissed",
    type: TYPE_KISS,
  },
  {
    name: "laughter",
    reaction: "is_laughed",
    type: TYPE_LAUGHTER,
  },
  {
    name: "sadness",
    reaction: "is_sad",
    type: TYPE_SADNESS,
  },
];
