import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploaded: false,
  send: false,
  items: [],
  dialog: null,
  token: null,
  error: {
    show: false,
    message: null,
  },
};

const mailMessagesSlice = createSlice({
  name: "mail/messages",
  initialState,
  reducers: {
    toggleSend: (state, { payload }) => {
      if (state.send !== payload) {
        state.send = payload;
      }
    },
    setDialog: (state, { payload }) => {
      state.dialog = payload;
    },
    clearItems: (state) => {
      state.uploaded = false;
      state.send = false;
      state.items = [];
      state.dialog = null;
      state.token = null;
      state.error = {
        show: false,
        message: null,
      };
    },
    addItems: (state, { payload }) => {
      state.uploaded = true;
      state.send = false;
      state.items = state.items.concat(payload.items);
      state.dialog = payload.more ? state.dialog : payload.dialog;
      state.token = payload.token ?? state.token;
    },
    setError: (state, { payload }) => {
      if (state.dialog?.uid !== payload?.uid) {
        return state;
      }

      state.error.show = !!payload?.message;
      state.error.message = payload?.message ?? null;
    },
    toggleFavorite: (state, { payload }) => {
      if (payload.user_uid === state?.dialog?.user_id) {
        state.dialog.is_favored = payload.is_favored;
      }
    },
    toggleRead: (state, { payload }) => {
      if (payload.user_uid === state?.dialog?.user_id) {
        state.dialog.is_new = payload.is_new;
      }
    },
    updateMedia: (state, { payload }) => {
      if (!state.items?.length) {
        return state;
      }

      for (let iteration = 0; iteration < state.items.length; iteration++) {
        if (state.items[iteration]?.uid === payload.uid) {
          if (state.items[iteration]?.files?.length > 0) {
            for (
              let iteration2 = 0;
              iteration2 < state.items[iteration]?.files.length;
              iteration2++
            ) {
              if (
                state.items[iteration]?.files[iteration2]?.uid ===
                payload.media.uid
              ) {
                state.items[iteration].files[iteration2] = payload.media;

                break;
              }
            }
          }

          break;
        }
      }
    },
  },
});

export const {
  toggleSend,
  setDialog,
  clearItems,
  addItems,
  setError,
  toggleFavorite,
  updateMedia,
  toggleRead,
} = mailMessagesSlice.actions;

export default mailMessagesSlice.reducer;
