import { GENDER_FEMALE } from "shared/constants";
import { GTM_SEX_FEMALE, GTM_SEX_MALE } from "shared/constants/analytics";
import { COOKIE_SIMPLE_USER_DATA } from "shared/constants/cookie";

/**
 * Set cookie
 * @param {string} name Cookie name
 * @param {*} value Cookie value
 * @param {null|number} exdays How many days cookie will alive
 * @param {boolean} subdomains Set cookie for subdomain or not
 * @param {null|number} min How many minutes cookie will alive. If set null set expire data by parameter exdays
 */
export function setCookie(
  name,
  value,
  exdays = null,
  subdomains = false,
  min = null
) {
  let expires = "";

  if (exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    expires = "expires=" + d.toUTCString() + ";";
  }

  if (min) {
    const date = new Date();
    date.setTime(date.getTime() + min * 60 * 1000);
    expires = "expires=" + date.toUTCString() + ";";
  }

  let domain = window.location.hostname;

  if (subdomains) {
    domain = domain.split(".");
    domain = domain[domain.length - 2] + "." + domain[domain.length - 1];
  }

  document.cookie =
    name +
    "=" +
    value +
    ";" +
    expires +
    "path=/" +
    (subdomains ? ";domain=." + domain : "");
}

/**
 * Get cookie value
 * @param {string} cname Cookie name
 * @returns {string|boolean}
 */
export function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
}

/**
 * Delete cookie
 * @param {string} name Cookie name
 */
export function deleteCookie(name) {
  setCookie(name, "", -1, true);
  setCookie(name, "", -1);
}

/**
 * Check cookie exist or not
 * @param {string} name Cookie name
 * @returns {boolean}
 */
export function existCookie(name) {
  const cookie = getCookie(name);

  if (!cookie || cookie.length <= 0) {
    return false;
  }

  return true;
}

/**
 * Delete hot jar cookie that crash application
 */
export function dropHotjarCrashCookie() {
  const prefix = "_hj";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(prefix) === 0) {
      const cookieParts = c.split("=");
      const name = cookieParts[0];

      if (!name) {
        return;
      }

      const value = c.replace(name + "=", "");

      if (typeof value === "string") {
        try {
          const json = JSON.parse(value);

          if (typeof json === "object" && Object.keys(json).length > 0) {
            deleteCookie(name);
          }
        } catch (e) {
          console.log(e.toString());
        }
      }
    }
  }
}

/**
 * Set simple data of user for payment service
 * @param {{uid: Number, age: Number, gender: number, profile_uid: Number, balance: Number}} data Simple user data
 */
export function setUserSimpleCookieData(data) {
  try {
    const storeData = getCookie(COOKIE_SIMPLE_USER_DATA);
    const newData = Object.assign(
      {},
      storeData ? JSON.parse(storeData) : {},
      data
    );

    setCookie(
      COOKIE_SIMPLE_USER_DATA,
      encodeURIComponent(JSON.stringify(newData)),
      30 * 34 * 60,
      true
    );
  } catch (e) {
    console.error(e.toString());
  }
}

/**
 * Return profile simple data
 * @return {{}|{credits_rest: (number|number), profile_id: (string|undefined|string), sex: (string), item_name: (string|undefined|string), userId: (string|undefined|string), age: (number|number)}}
 */
export const getUserSimpleCookieData = () => {
  const storeData = getCookie(COOKIE_SIMPLE_USER_DATA);

  if (!storeData) {
    return {};
  }

  try {
    const data = JSON.parse(decodeURIComponent(storeData));

    return {
      userId: data?.uid ? data?.uid.toString() : "",
      age: data?.age ? +data?.age : 0,
      sex: data?.gender === GENDER_FEMALE ? GTM_SEX_FEMALE : GTM_SEX_MALE,
      profile_id: data?.profile_uid ? data?.profile_uid.toString() : "",
      item_name: data?.profile_uid ? data?.profile_uid.toString() : "Credits",
      credits_rest: data?.balance ? +data?.balance : 0,
    };
  } catch (e) {
    console.error(e.toString());
  }

  return {};
};
