import { getCookie } from "shared/helpers/cookie";
import { isTrue } from "shared/helpers/view";

const reportWebVitals = (onPerfEntry) => {
  if (
    isTrue(getCookie("DEV_MODE")) &&
    onPerfEntry &&
    onPerfEntry instanceof Function
  ) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
