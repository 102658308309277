export const LIMIT_ACTIVITY_REQUEST = 50;

export const ACTIVITY_TYPE_LIKE = 1;
export const ACTIVITY_TYPE_WINK = 2;
export const ACTIVITY_TYPE_VISITOR = 3;
export const ACTIVITY_TYPE_FAVORITE = 4;
export const ACTIVITY_TYPE_MATCH = 5;
export const ACTIVITY_TYPE_MAIL = 6;
export const ACTIVITY_TYPE_CHAT = 7;
export const ACTIVITY_TYPE_LIVE_STREAM = 19;
export const ACTION_UNREAD = 0;
export const ACTION_READ = 1;

export const ACTIVITY_TYPE_ADVERTISEMENT = "ADVERTISEMENT";
export const ACTIVITY_TYPE_ADVERTISEMENT_TEXT_CHAT = "Has sent you a message!";
