import { createSlice } from "@reduxjs/toolkit";

import {
  IS_OFFLINE,
  MAX_USER_AGE,
  MIN_USER_AGE,
  NOT_FAVORED,
  STREAM_NOT_LIVE,
  USER_VERIFIED,
} from "shared/constants";

export const initialState = {
  age_from: null,
  age_to: null,
  is_verified: USER_VERIFIED,
  is_online: IS_OFFLINE,
  is_streaming: STREAM_NOT_LIVE,
  is_favored: NOT_FAVORED,
  country_code: null,
  body_type: null,
  submit: false,
  submittedAt: 0,
};

const searchFilterSlice = createSlice({
  name: "search/filter",
  initialState,
  reducers: {
    set: (state, { payload }) => {
      if (payload && Object.keys(payload).length > 0) {
        for (const [attribute, value] of Object.entries(payload)) {
          if (typeof initialState[attribute] !== "undefined") {
            state[attribute] = value;
          }
        }
      }
    },

    resetFilter: (state, { payload }) => {
      return {
        age_from: MIN_USER_AGE,
        age_to: MAX_USER_AGE,
        is_verified: USER_VERIFIED,
        is_online: IS_OFFLINE,
        is_streaming: STREAM_NOT_LIVE,
        is_favored: NOT_FAVORED,
        country_code: null,
        body_type: null,
        submit: true,
        submittedAt: Date.now(),
        ...(!!payload && payload),
      };
    },
  },
});

export const { set, resetFilter } = searchFilterSlice.actions;

export default searchFilterSlice.reducer;
