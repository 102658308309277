import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  init: false,
  messages: [],
  updatedAt: 0,
};

const sliceNotifySocket = createSlice({
  name: "user/notify/socket",
  initialState,
  reducers: {
    setInitSocket: (state) => {
      state.init = true;
    },
    addMessage: (state, { payload }) => {
      state.messages.push({
        uid: `${Date.now()}_${payload.uid}_${payload.type}_${
          Math.floor(Math.random() * 1000) + 1
        }`,
        message: payload,
      });
    },
    removeMessage: (state, { payload }) => {
      for (let it = 0; it < state.messages.length; it++) {
        if (state.messages[it].uid === payload) {
          state.messages.splice(it, 1);
          break;
        }
      }
    },
  },
});

export const { setInitSocket, addMessage, removeMessage } =
  sliceNotifySocket.actions;

export default sliceNotifySocket.reducer;
