import {
  ERROR_STATUS_UNAUTHORIZED,
  MODERATION_ERROR_CODE,
} from "shared/constants";
import {
  GOOGLE_SIGN_IN_URL,
  LOGIN_URL,
  REGISTARTION_URL,
  RESET_PASSWORD_NEW_URL,
  RESTORE_PASSWORD_URL,
  SEND_CONTACT_US_URL,
} from "shared/constants/apiUrls";

import { post } from "./axios-simple";

/**
 * Check is user authorized or not
 * @param {Object} response Http response object
 * @returns {boolean}
 */
export const isAuthorized = (response) => {
  if (+response?.status === ERROR_STATUS_UNAUTHORIZED) {
    if (response?.data?.errors?.length > 0) {
      for (let i = 0; i < response.data.errors.length; i++) {
        const error = response.data.errors[i];

        if (+error?.code === MODERATION_ERROR_CODE) {
          window.location.href = "/moderation/";
          return false;
        }
      }
    }

    window.location.href = import.meta.env.VITE_APP_LOGOUT_URL;
    return false;
  }

  return true;
};

/**
 * Registration user by api
 * @param {Object} data Request data
 */
export const registrationUser = (data) => {
  return post(import.meta.env.VITE_APP_API_HOST + REGISTARTION_URL, data);
};

/**
 * Login user by api
 * @param {Object} data Request data
 */
export const loginUser = (data) => {
  return post(import.meta.env.VITE_APP_API_HOST + LOGIN_URL, data);
};

/**
 * Send request for sin in with Google
 * @param {{access_token: string, google_id: string}}data
 * @return {Promise<AxiosResponse<*>>}
 */
export const sinInWithGoogle = (data) => {
  return post(import.meta.env.VITE_APP_API_HOST + GOOGLE_SIGN_IN_URL, data);
};

/**
 * Send request for reset password
 * @param {{data}} data user obj
 * @return {Promise<AxiosResponse<*>>}
 */
export const resetPassword = (data) => {
  return post(import.meta.env.VITE_APP_API_HOST + RESTORE_PASSWORD_URL, data);
};

/**
 * Send request for contact us
 * @param {{data}} data user obj
 * @return {Promise<AxiosResponse<*>>}
 */
export const sendContactUs = (data) => {
  return post(import.meta.env.VITE_APP_API_HOST + SEND_CONTACT_US_URL, data);
};

/**
 * Send request for update password
 * @param {{data}} data user obj
 * @return {Promise<AxiosResponse<*>>}
 */
export const updatePassword = (data) => {
  return post(import.meta.env.VITE_APP_API_HOST + RESET_PASSWORD_NEW_URL, data);
};
