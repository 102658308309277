import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: {},
};

const mailFilesSlice = createSlice({
  name: "mail/files",
  initialState,
  reducers: {
    set: (state, { payload }) => {
      state.items[payload.identity] = {
        files: payload.files,
      };
    },
    addItem: (state, { payload }) => {
      if (!state.items[payload.identity]) {
        state.items[payload.identity] = {
          files: [],
        };
      }

      const alreadyExist = updateFileItem(
        state,
        payload.identity,
        payload.file.uid,
        payload.file
      );

      if (!alreadyExist) {
        state.items[payload.identity].files.push(payload.file);
      }
    },
    updateItem: (state, { payload }) => {
      updateFileItem(state, payload.identity, payload.fileUid, payload.file);
    },
    deleteItem: (state, { payload }) => {
      if (state.items[payload.identity]?.files) {
        let iteration = 0;
        for (const file of state.items[payload.identity].files) {
          if (file.uid === payload.file.uid) {
            state.items[payload.identity].files.splice(iteration, 1);
            break;
          }

          iteration++;
        }
      }
    },
    toggleSend: (state, { payload }) => {
      updateFileItem(state, payload.identity, payload.file.uid, null, {
        send: payload.send,
      });
    },
    moveFiles: (state, { payload }) => {
      if (
        payload.identity === payload.tmpIdentity ||
        !state.items[payload.tmpIdentity]
      ) {
        return state;
      }

      state.items[payload.identity] = state.items[payload.tmpIdentity];
    },
    clearFiles: (state, { payload }) => {
      if (!state.items[payload.identity]) {
        return state;
      }

      state.items[payload.identity].files = [];
    },
    setError: (state, { payload }) => {
      updateFileItem(state, payload.identity, payload.fileUid, null, {
        error: {
          status: !!payload.status,
          message: null,
        },
      });
    },
  },
});

/**
 * Upadte file or file attribute
 * @param {Object} state State object
 * @param {string|number} identity Mail dialog identity
 * @param {string|number} fileUid File uid
 * @param {null|Object} newFile New file object
 * @param {null|Object} updateAttributes Change attribute list
 * @return {boolean}
 */
const updateFileItem = (
  state,
  identity,
  fileUid,
  newFile = null,
  updateAttributes = null
) => {
  if (!state.items[identity] || !state.items[identity]?.files?.length) {
    return false;
  }

  let iteration = 0;
  let updated = false;

  for (const file of state.items[identity].files) {
    if (file.uid === fileUid) {
      if (newFile) {
        state.items[identity].files[iteration] = newFile;
      }

      if (updateAttributes) {
        Object.keys(updateAttributes).forEach((attribute) => {
          state.items[identity].files[iteration] = {
            ...state.items[identity].files[iteration],
            ...{
              [attribute]: updateAttributes[attribute],
            },
          };
        });
      }

      updated = true;
      break;
    }

    iteration++;
  }

  return updated;
};

export const {
  set,
  addItem,
  updateItem,
  deleteItem,
  toggleSend,
  moveFiles,
  clearFiles,
} = mailFilesSlice.actions;

export default mailFilesSlice.reducer;
