import { createSlice } from "@reduxjs/toolkit";

import { TIME_1_MINUTE } from "shared/constants/stream";

const initialState = {
  init: false,
  io: null,
  isConnected: false,
  isAuthorized: false,
  timeToExpire: TIME_1_MINUTE,
  doNotDisturb: false,
  showTimeModal: false,
  streamStopped: false,
  isFailure: false,
  attemptsCount: 0,
  connectionError: false,
  notEnoughCredits: false,
  haveToReconnect: false,
  haveToLeave: false,

  checkOnLive: null,
};

const streamSlice = createSlice({
  name: "stream",
  initialState,
  reducers: {
    init: (state) => {
      state.init = true;
    },
    initIo: (state, { payload }) => {
      state.io = payload;
    },
    toggleCheckOnLive: (state, { payload }) => {
      state.checkOnLive = payload;
    },
    streamSocketConnected: (state, { payload }) => {
      state.isConnected = payload;
    },
    streamSocketAuthorize: (state, { payload }) => {
      state.isAuthorized = payload;
    },
    setStreamConnectionError: (state, { payload }) => {
      state.connectionError = payload;
    },
    setStreamStopped: (state, { payload }) => {
      state.streamStopped = payload;
    },
    setShowTimeModal: (state, { payload }) => {
      state.showTimeModal = payload;
    },
    setStreamAttempts: (state, { payload }) => {
      state.attemptsCount = payload;
    },
    setStreamFailure: (state, { payload }) => {
      state.isFailure = payload;
    },
    streamClearData: (state) => {
      return {
        ...state,
        timeToExpire: TIME_1_MINUTE,
        doNotDisturb: false,
        showTimeModal: false,
        streamStopped: false,
        isFailure: false,
        attemptsCount: 0,
        connectionError: false,
        notEnoughCredits: false,
        haveToReconnect: false,
        haveToLeave: false,
      };
    },
    setStreamNotEnough: (state, { payload }) => {
      state.notEnoughCredits = payload;
    },
    setStreamExpireTime: (state, { payload }) => {
      state.timeToExpire = payload;
    },
    setStreamUserDisturb: (state, { payload }) => {
      state.doNotDisturb = payload;
    },
    setHaveToReconnect: (state, { payload }) => {
      state.haveToReconnect = payload;
    },
    setHaveToLeave: (state, { payload }) => {
      state.haveToLeave = payload;
    },
  },
});

export const {
  init,
  initIo,
  toggleCheckOnLive,
  streamSocketConnected,
  streamSocketAuthorize,
  setStreamConnectionError,
  setStreamStopped,
  setShowTimeModal,
  setStreamAttempts,
  setStreamFailure,
  streamClearData,
  setStreamNotEnough,
  setStreamExpireTime,
  setStreamUserDisturb,
  setHaveToReconnect,
  setHaveToLeave,
} = streamSlice.actions;

export default streamSlice.reducer;
