import { createSlice } from "@reduxjs/toolkit";

import {
  GENDER_FEMALE,
  IMAGE_PRIVATE,
  IS_OFFLINE,
  TYPE_PHOTO,
  TYPE_VIDEO,
  USER_NOT_VERIFIED,
} from "shared/constants";

const initialState = {
  show: false,
  items: [],
  itemsPhotoUid: [],
  itemsVideoUid: [],
  likedContent: [],
  loaders: [],
  currentItemIndex: null,
  uid: null,
  username: null,
  avatar: null,
  age: null,
  is_online: IS_OFFLINE,
  verified: USER_NOT_VERIFIED,
  gender: GENDER_FEMALE,
  is_liked: false,
  isDisableLike: false,
  likeButtonText: null,
  likeButtonTextNews: null,
  showLikeButton: false,
  showDeleteButton: false,
  showCommunicationButton: false,
  showReactionButton: false,
  isOwner: false,
  isGallery: false,
  handleClickItem: null,
  handleClickLike: null,
  handleClickMail: null,
  handleClickChat: null,
  handleClickDelete: null,
  handleClickClose: null,
  contestCbAction: null,
  costPrivatePhoto: null,
  costPrivateVideo: null,
  imageAbsolute: false,
  showedOnlyVideo: false,
  contestData: null,
  currentReaction: null,
  handleSetReaction: null,
  setCurrentReaction: null,
  currentContent: { uid: null, type: null },
  isSingle: false,
  error: {
    index: null,
    message: null,
  },
};

const mediaGallerySlice = createSlice({
  name: "media-gallery",
  initialState,
  reducers: {
    set: (state, { payload }) => {
      state.show = true;
      state.uid = payload?.uid ?? null;
      state.username = payload?.username ?? null;
      state.avatar = payload?.avatar ?? null;
      state.age = payload?.age ?? null;
      state.is_online = payload?.is_online ?? IS_OFFLINE;
      state.verified = payload?.verified ?? USER_NOT_VERIFIED;
      state.gender = payload?.gender ?? GENDER_FEMALE;
      state.is_liked = payload?.is_liked ?? false;
      state.isDisableLike = payload?.isDisableLike ?? false;
      state.showLikeButton = !!payload?.showLikeButton;
      state.showDeleteButton = !!payload?.showDeleteButton;
      state.showCommunicationButton = !!payload?.showCommunicationButton;
      state.isOwner = !!payload?.isOwner;
      state.handleClickItem = payload?.handleClickItem ?? null;
      state.costPrivatePhoto = payload?.costPrivatePhoto ?? null;
      state.costPrivateVideo = payload?.costPrivateVideo ?? null;
      state.imageAbsolute = payload?.imageAbsolute ?? false;
      state.handleClickLike = payload?.handleClickLike ?? null;
      state.handleClickMail = payload?.handleClickMail ?? null;
      state.handleClickChat = payload?.handleClickChat ?? null;
      state.handleClickDelete = payload?.handleClickDelete ?? null;
      state.handleClickClose = payload?.handleClickClose ?? null;
      state.likeButtonText = payload?.likeButtonText ?? null;
      state.showedOnlyVideo = payload?.showedOnlyVideo ?? false;
      state.contestData = payload?.contestData ?? null;
      state.contestCbAction = payload?.contestCbAction ?? null;
      state.likeButtonTextNews = payload.likeButtonTextNews ?? null;
      state.isGallery = payload?.isGallery ?? null;
      state.isSingle = payload?.isSingle ?? false;
      state.showReactionButton = payload?.showReactionButton ?? false;
      state.currentReaction = payload?.currentReaction ?? null;
      state.handleSetReaction = payload?.handleSetReaction ?? null;
      state.setCurrentReaction = payload?.setCurrentReaction ?? null;

      prepareMedia(state, payload.items, payload?.current);
      return state;
    },
    setCurrentIndex: (state, { payload }) => {
      state.currentItemIndex = payload;
      state.error.index = null;
      state.error.message = null;
    },
    clearGallery: () => {
      return initialState;
    },
    setLike: (state, { payload }) => {
      state.is_liked = payload;
    },
    setReaction: (state, { payload }) => {
      state.currentReaction = payload;
    },
    setDisable: (state, { payload }) => {
      state.isDisableLike = payload;
    },
    toggleLoader: (state, { payload }) => {
      const position = state.loaders.indexOf(payload.imageUid);

      if (payload.status && position < 0) {
        state.loaders.push(payload.imageUid);
      } else if (!payload.status && position >= 0) {
        state.loaders.splice(position, 1);
      }
    },
    updateItem: (state, { payload }) => {
      for (const [index, item] of state.items.entries()) {
        if (item.uid === payload?.uid) {
          state.items[index] = payload;
          const position = state.loaders.indexOf(payload?.uid);

          if (position >= 0) {
            state.loaders.splice(position, 1);
          }
          break;
        }
      }
    },
    setSlideError: (state, { payload }) => {
      if (payload.index === state.currentItemIndex) {
        state.error.index = payload.index;
        state.error.message = payload.message;
      }
    },
    setCurrentContent: (state, { payload }) => {
      state.currentContent = payload;
    },

    addLikedContent: (state, { payload }) => {
      if (!state.likedContent.includes(payload)) {
        state.likedContent.push(payload);
      }
    },
    removeLikedContent: (state, { payload }) => {
      state.likedContent.filter((i) => i !== payload);
    },

    removeItem: (state, { payload }) => {
      let nextIndex = null;
      state.items = state.items.filter((item, index) => {
        if (item.uid === payload.uid) {
          nextIndex = state.items[index + 1]
            ? index
            : state.items[index - 1]
              ? index - 1
              : null;
          return false;
        }
        return true;
      });

      if (state.loaders.indexOf(payload.uid) >= 0) {
        state.loaders.splice(state.loaders.indexOf(payload.uid), 1);
      }

      if (nextIndex === null) {
        clearState(state);
      } else {
        state.currentItemIndex = nextIndex;
      }
    },
    setPoints: (state, { payload }) => {
      state.contestData = {
        ...state.contestData,
        photo: { ...state.contestData?.photo, counter: payload },
      };
    },
  },
});

/**
 * Prepare media gallery data
 * @param {Object} state Media gallery object
 * @param {Array|Object} items List of media files
 * @param {number|null} current Selected id of media files
 */
const prepareMedia = (state, items, current = null) => {
  const images = [];
  const privateImages = [];
  const video = [];
  const privateVideo = [];
  const privateVideoUids = [];
  const privatePhotoUids = [];

  const activeSlide = {
    position: 0,
    type: null,
    private: null,
  };

  Object.keys(items).forEach((key) => {
    const item = items[key];

    if (current === item.uid) {
      activeSlide.type = item.type;
      activeSlide.private = item.private;
    }

    if (item.type === TYPE_VIDEO && item.private === IMAGE_PRIVATE) {
      privateVideo.push(item);
      privateVideoUids.push(item.uid);
      if (current === item.uid) activeSlide.position = privateVideo.length - 1;
    } else if (item.type === TYPE_VIDEO && item.private !== IMAGE_PRIVATE) {
      video.push(item);
      state.itemsVideoUid.push(item.uid);
      if (current === item.uid) activeSlide.position = video.length - 1;
    } else if (item.type === TYPE_PHOTO && item.private === IMAGE_PRIVATE) {
      privateImages.push(item);
      privatePhotoUids.push(item.uid);
      if (current === item.uid) activeSlide.position = privateImages.length - 1;
    } else if (item.type === TYPE_PHOTO && item.private !== IMAGE_PRIVATE) {
      images.push(item);
      state.itemsPhotoUid.push(item.uid);
      if (current === item.uid) activeSlide.position = images.length - 1;
    }
  });

  state.items = images.concat(video, privateImages, privateVideo);
  state.itemsVideoUid = state.itemsVideoUid.concat(privateVideoUids);
  state.itemsPhotoUid = state.itemsPhotoUid.concat(privatePhotoUids);

  if (!current) {
    state.currentItemIndex = 0;
  } else {
    if (
      activeSlide.type === TYPE_VIDEO &&
      activeSlide.private === IMAGE_PRIVATE
    ) {
      state.currentItemIndex =
        images.length +
        video.length +
        privateImages.length +
        activeSlide.position;
    } else if (
      activeSlide.type === TYPE_VIDEO &&
      activeSlide.private !== IMAGE_PRIVATE
    ) {
      state.currentItemIndex = images.length + activeSlide.position;
    } else if (
      activeSlide.type === TYPE_PHOTO &&
      activeSlide.private === IMAGE_PRIVATE
    ) {
      state.currentItemIndex =
        images.length + video.length + activeSlide.position;
    } else if (
      activeSlide.type === TYPE_PHOTO &&
      activeSlide.private !== IMAGE_PRIVATE
    ) {
      state.currentItemIndex = activeSlide.position;
    }
  }
};

/**
 * Clear state object
 * @param {Object} state Redux storage object
 */
const clearState = (state) => {
  Object.keys(initialState).forEach((key) => {
    state[key] = initialState[key];
  });
};

export const {
  addLikedContent,
  clearGallery,
  removeItem,
  removeLikedContent,
  set,
  setCurrentContent,
  setCurrentIndex,
  setDisable,
  setLike,
  setReaction,
  setPoints,
  setSlideError,
  toggleLoader,
  updateItem,
} = mediaGallerySlice.actions;

export default mediaGallerySlice.reducer;
