export const REAL_GIFTS_TYPE = "realGifts";
export const VIRTUAL_GIFTS_TYPE = "virtualGifts";
export const WELCOME_POPUP_TYPE = "welcomePopup";
export const GALLERY_SWIPE_TYPE = "gallerySwipe";
export const FEED_UPDATE_TYPE = "feedUpdate";

export const onboardingConfig = {
  1: REAL_GIFTS_TYPE,
  2: VIRTUAL_GIFTS_TYPE,
  3: WELCOME_POPUP_TYPE,
  4: GALLERY_SWIPE_TYPE,
  5: FEED_UPDATE_TYPE,
};
