import { createSlice } from "@reduxjs/toolkit";

import { setLocalStorageData } from "shared/helpers/view";

const initialState = {
  prev: [],
  current: null,
  isFromCache: false,
};

const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    updateRout: (state, { payload }) => {
      state.prev = state.current ? [...state.prev, state.current] : [];
      state.current = payload;
      setLocalStorageData("locationData", {
        current: state.current,
        prev: [...state.prev],
      });
    },
    backRout: (state, { payload }) => {
      state.prev.pop();
      state.prev = state.current ? [...state.prev] : [];
      state.current = payload;
      setLocalStorageData("locationData", {
        current: state.current,
        prev: [...state.prev],
      });
    },
    setRout: (state, { payload }) => {
      state.prev = [...payload.prev];
      state.current = payload.current;
      if (payload.isFromCache) {
        state.isFromCache = payload.isFromCache;
      }
    },
    clearRout: (state, { payload }) => {
      state.prev = [];
      state.current = payload;
      state.isFromCache = false;
      setLocalStorageData("locationData", {
        current: state.current,
        prev: [...state.prev],
      });
    },
    clearIsCacheParam: (state) => {
      state.isFromCache = false;
    },
  },
});

export const { updateRout, backRout, setRout, clearRout, clearIsCacheParam } =
  routeSlice.actions;

export default routeSlice.reducer;
