import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  totalItems: 0,
  page: 1,
  pages: 1,
  send: false,
  uploaded: false,
};

const blockedUsersSlice = createSlice({
  name: "blockedUsers",
  initialState,
  reducers: {
    toggleSendBlockedUsers: (state, { payload }) => {
      state.send = payload;
    },
    setBlockedUserData: (state, { payload }) => {
      return {
        ...state,
        ...{
          items: payload.items,
          totalItems: payload.totalItems,
          page: payload.page,
          pages: payload.pages,
          send: payload.send,
          uploaded: true,
        },
      };
    },
    clearItems: (state) => {
      state.items = initialState.items;
      state.uploaded = false;
    },
    deleteItemById: (state, { payload }) => {
      state.items = state.items.filter((item) => item.uid !== payload);
    },
  },
});

export const {
  toggleSendBlockedUsers,
  deleteItemById,
  clearItems,
  setBlockedUserData,
} = blockedUsersSlice.actions;
export default blockedUsersSlice.reducer;
