import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  banners: {},
};

const bannersSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    setBanner: (state, { payload }) => {
      state.banners[payload?.place] = payload ?? {};
      state.banners[payload?.place].upload = true;
    },
    setBannerNotFound: (state, { payload }) => {
      state.banners[payload] = {};
      state.banners[payload].upload = true;
      state.banners[payload].notFound = true;
    },
  },
});

export const { setBanner, setBannerNotFound } = bannersSlice.actions;

export default bannersSlice.reducer;
