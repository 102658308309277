import { REACTIONS } from "shared/constants/news.js";

/**
 * Sum of count reactions
 * @param {object} item
 * @returns {number}
 */
export const countReactions = (item) => {
  return Object.keys(item)
    .filter((key) => key.endsWith("_count"))
    .reduce((sum, key) => sum + item[key], 0);
};

/**
 Find current reaction
 * @param {itemData} item - The item containing reactions
 * @returns {ReactionItem | null} - The current active reaction or null
 **/

export const findCurrentReaction = (item) => {
  const currentReaction = REACTIONS.find(
    (reaction) => item[reaction.reaction] === true
  );
  return currentReaction || null;
};
