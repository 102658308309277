import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  statusDesputeItem: [],
  send: true,
  uploaded: false,
  updateTime: null,
  profileStatusCode: 200,
  loadMore: true,
};

const profileBillingSlice = createSlice({
  name: "profile/billing",
  initialState,
  reducers: {
    set: (state, { payload }) => {
      state.items = payload.loadMore
        ? state.items.concat(payload.items)
        : payload.items;
      state.send = false;
      state.uploaded = true;
      state.updateTime = payload.items.length
        ? payload.items[payload.items.length - 1].updatedAtMs
        : null;
      state.loadMore = payload.items?.length >= 60;
    },
    setStatusResponse: (state, { payload }) => {
      state.profileStatusCode = payload;
    },
    toggleSend: (state, { payload }) => {
      state.send = payload;
    },
    clearBillingItems: (state, { payload }) => {
      state.items = payload;
    },
    setStatusDespute: (state, { payload }) => {
      state.statusDesputeItem = payload;
    },
  },
});

export const {
  set,
  toggleSend,
  clearBillingItems,
  setStatusDespute,
  setStatusResponse,
} = profileBillingSlice.actions;

export default profileBillingSlice.reducer;
