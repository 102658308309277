import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalInfo: {
    uid: null,
    vote: null,
    boost: null,
    has_free_vote: false,
  },
  profilesList: {
    items: [],
    total: null,
    page: 1,
    limit: 20,
    isLoading: false,
    isLoadingMore: false,
    errors: null,
  },
  filter: {
    sort: 0,
    search: "",
    filterType: "all",
    mode: null,
  },
  sendVote: {
    vote: {},
    boost: {},
  },
};

const contestSlice = createSlice({
  name: "contest",
  initialState,
  reducers: {
    set: (state, { payload }) => {
      state.totalInfo = payload;
    },
    updateFreeVote: (state) => {
      state.totalInfo.has_free_vote = false;
    },
    setList: (state, { payload }) => {
      if (state.profilesList.isLoadingMore) {
        state.profilesList.items = [
          ...state.profilesList.items,
          ...payload.list,
        ];
      } else {
        state.profilesList.items = payload.list;
      }

      state.profilesList.total = payload.total;
      state.profilesList.limit = payload.limit;
      state.filter.mode = payload.mode;
      state.profilesList.page = payload.page;
      state.profilesList.errors = null;
    },
    setListFailed: (state, { payload }) => {
      state.profilesList.errors = payload.error;
      state.profilesList.items = [];
    },
    setLoading: (state, { payload }) => {
      state.profilesList.isLoading = payload;
    },
    setLoadingMore: (state, { payload }) => {
      state.profilesList.isLoadingMore = payload;
    },
    clearContest: (state) => {
      state.profilesList = initialState.profilesList;
      state.filter = initialState.filter;
    },
    updateFilter: (state, { payload }) => {
      state.filter = {
        ...state.filter,
        ...payload,
      };
    },
    updateSendVote: (state, { payload: { profileUid, type, place } }) => {
      const typeVote = type === 1 ? "vote" : "boost";

      state.sendVote[typeVote] = {
        ...state.sendVote[typeVote],
        [profileUid]: {
          isLoading: true,
          success: null,
          error: null,
          place,
        },
      };
    },
    clearSendVote: (state, { payload: { type, profileUid } }) => {
      const { [profileUid]: _, ...rest } = state.sendVote[type];

      state.sendVote[type] = rest;
    },
    updateProfilePoins: (state, { payload }) => {
      const indexProfile = state.profilesList.items.findIndex(
        (item) => item.profile_uid === payload.profileUid
      );
      const type = payload.type === 1 ? "vote" : "boost";

      state.sendVote[type][payload.profileUid] = {
        ...state.sendVote[type][payload.profileUid],
        isLoading: false,
        success: true,
      };

      if (indexProfile !== -1) {
        state.profilesList.items[indexProfile] = {
          ...state.profilesList.items[indexProfile],
          counter: payload.counter,
        };
      }
    },
    updateListMoreData: (state, { payload }) => {
      state.profilesList.items = state.profilesList.items.map((item) => ({
        ...item,
        ...payload[item.profile_uid],
      }));
    },
    toggleFavorite: (state, { payload }) => {
      for (const [index, item] of state.profilesList.items.entries()) {
        if (item.profile_uid === payload.uid) {
          if (item.isFavored !== payload.favorite) {
            state.profilesList.items[index].isFavored = payload.favorite;
            break;
          }
        }
      }

      return state;
    },
  },
});

export const {
  set,
  setList,
  setLoading,
  updateFilter,
  updateProfilePoins,
  updateListMoreData,
  setLoadingMore,
  updateSendVote,
  clearSendVote,
  updateFreeVote,
  setListFailed,
  toggleFavorite,
  clearContest,
} = contestSlice.actions;

export default contestSlice.reducer;
