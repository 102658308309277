import { createSlice } from "@reduxjs/toolkit";

import { APP_MOBILE } from "shared/constants";

import { getCountSearchItemsPerRow } from "shared/helpers/view";

const MAX_COUNT_OF_ITEMS_FOR_EMPTY_STATE = 4;

const initialState = {
  items: [],
  itemsUid: [],
  itemsLive: [],
  itemsLiveUid: [],
  lastUserBlockUid: [],
  isSearchEmptyState: false,
  emptyStateItems: [],
  token: "",
  total: 0,
  error: {
    error: false,
    message: "",
  },
  send: false,
  uploaded: false,
  searchRequestAt: 0,
  checkStreamStatus: false,
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    set: (state, { payload }) => {
      if (state.searchRequestAt > payload.filters.submittedAt) {
        return state;
      }
      state.send = false;
      state.uploaded = true;
      state.token = payload.data.token ?? "";
      state.searchRequestAt = payload.filters.submittedAt;

      const isMobileVersion =
        import.meta.env.VITE_APP_APPLICATION_TYPE === APP_MOBILE;

      if (payload.data.items.length <= MAX_COUNT_OF_ITEMS_FOR_EMPTY_STATE) {
        state.lastUserBlockUid = payload.data.items.map((item) => item.uid);
        state.isSearchEmptyState = true;
      }

      if (payload.liveItems.length > 0) {
        for (const item of payload.liveItems) {
          if (!state.itemsLiveUid.includes(item.uid)) {
            state.itemsLiveUid.push(item.uid);
            state.itemsLive.push(item);
          }
        }
      }

      prepareSearchItems(state, payload.data, isMobileVersion);
    },
    addLiveProfiles: (state, { payload }) => {
      if (payload.length > 0) {
        for (const item of payload) {
          if (!state.itemsLiveUid.includes(item.uid)) {
            state.itemsLiveUid.push(item.uid);
            state.itemsLive.push(item);
          }
        }
      }

      const isMobileVersion =
        import.meta.env.VITE_APP_APPLICATION_TYPE === APP_MOBILE;

      prepareSearchItems(state, { items: payload }, isMobileVersion);
    },
    toggleSend: (state, { payload }) => {
      state.send = payload.send;

      if (typeof state.uploaded !== "undefined") {
        state.uploaded = payload.uploaded;
      }
    },
    clearSearch: () => {
      return {
        items: [],
        itemsUid: [],
        itemsLive: [],
        itemsLiveUid: [],
        token: "",
        total: 0,
        isSearchEmptyState: false,
        emptyStateItems: [],
        error: {
          error: false,
          message: "",
        },
        send: false,
        uploaded: false,
      };
    },
    updateLiveStatus: (state, { payload }) => {
      if (!payload || Object.keys(payload).length === 0) {
        return state;
      }

      const itemsLive = [];
      const itemsLiveUid = [];

      state.items.forEach((item, key) => {
        if (
          typeof payload[item.uid] !== "undefined" &&
          state.items[key].in_stream !== payload[item.uid]
        ) {
          state.items[key].in_stream = payload[item.uid];
        }
      });

      state.itemsLive.forEach((item) => {
        if (typeof payload[item.uid] !== "undefined" && payload[item.uid]) {
          itemsLive.push(item);
          itemsLiveUid.push(item.uid);
        }
      });

      state.itemsLiveUid = itemsLiveUid;
      state.itemsLive = itemsLive;
    },
    toggleFavorite: (state, { payload }) => {
      for (const [index, item] of state.items.entries()) {
        if (item.uid === payload.uid) {
          if (item.is_favored !== payload.favorite) {
            state.items[index].is_favored = payload.favorite;
            break;
          }
        }
      }

      for (const [index, item] of state.emptyStateItems.entries()) {
        if (item.uid === payload.uid) {
          if (item.is_favored !== payload.favorite) {
            state.emptyStateItems[index].is_favored = payload.favorite;
            break;
          }
        }
      }

      return state;
    },
    toggleStreamStatus: (state, { payload }) => {
      if (state.checkStreamStatus !== payload) {
        state.checkStreamStatus = payload;
      }
    },
    hideUserFromSearch: (state, { payload }) => {
      state.items = state.items.filter((item) => item.uid !== payload);
      state.itemsUid = state.itemsUid.filter((item) => item !== payload);
      state.itemsLive = state.itemsLive.filter((item) => item.uid !== payload);
      state.itemsLiveUid = state.itemsLiveUid.filter(
        (item) => item !== payload
      );
    },
    setItemsForEmptyState: (state, { payload }) => {
      state.emptyStateItems = payload;
    },
  },
});

/**
 * Prepare search feed
 * @param {Object} state Object storage
 * @param {Object} payload Data fr update
 * @param {boolean} isMobile Current version of site is mobile
 */
const prepareSearchItems = (state, payload, isMobile) => {
  const countItemsInChunk = isMobile ? getCountSearchItemsPerRow() * 2 : 8;
  const itemChunks = [];
  const itemsUid = [];

  state.items = state.items.concat(payload.items);

  for (
    let itemKey = 0, chunkCounter = 0;
    itemKey < state.items.length;
    itemKey++
  ) {
    const item = state.items[itemKey];

    if (itemsUid.indexOf(item.uid) >= 0) {
      continue;
    }

    if (typeof itemChunks[chunkCounter] === "undefined") {
      itemChunks[chunkCounter] = [];
    }

    if (
      itemChunks[chunkCounter].length > 0 &&
      itemChunks[chunkCounter].length % countItemsInChunk === 0
    ) {
      chunkCounter++;
      itemChunks[chunkCounter] = [];
    }

    itemChunks[chunkCounter].push(item);
    itemsUid.push(item.uid);
  }

  const liveItems = [...state.itemsLive];

  state.items = [...state.items].map(
    (obj) => liveItems.find((o) => o.uid === obj.uid) || obj
  );

  state.itemsUid = state.itemsUid.concat(itemsUid);
};

export const {
  set,
  toggleSend,
  clearSearch,
  addLiveProfiles,
  toggleFavorite,
  updateLiveStatus,
  setItemsForEmptyState,
  toggleStreamStatus,
  hideUserFromSearch,
} = searchSlice.actions;

export default searchSlice.reducer;
