import { createSlice } from "@reduxjs/toolkit";

import {
  MAIL_ACTIVE_TAB_INBOX,
  MAIL_ANSWERED_TYPE_ALL,
} from "shared/constants/mail";
import { OPTION_DISABLED, OPTION_ENABLED } from "shared/constants/options";

const initialState = {
  uploaded: false,
  send: false,
  items: [],
  selectedItems: [],
  mailsCounter: {},
  activeTab: MAIL_ACTIVE_TAB_INBOX,
  answeredType: MAIL_ANSWERED_TYPE_ALL,
  selectAll: OPTION_DISABLED,
  token: null,
  error: {
    show: false,
    message: null,
  },
};

const mailDialogsSlice = createSlice({
  name: "mail/dialogs",
  initialState,
  reducers: {
    addItems: (state, { payload }) => {
      if (
        state.activeTab !== payload.activeTab ||
        state.answeredType !== payload.answeredType ||
        (state.uploaded && !payload.more)
      ) {
        return state;
      }
      state.send = false;
      state.uploaded = true;
      state.items = state.items.concat(payload?.items ?? []);
      state.token = +payload?.token ?? 0;

      if (state.selectAll === OPTION_ENABLED) {
        setSelectAll(state);
      }
    },
    toggleActiveTab: (state, { payload }) => {
      if (payload === state.activeTab) {
        return state;
      }

      state.activeTab = payload;
      state.send = true;
      state.uploaded = false;
      state.token = null;
      state.selectAll = OPTION_DISABLED;
      state.answeredType = MAIL_ANSWERED_TYPE_ALL;
      state.items = [];
      state.mailsCounter = [];
      state.selectedItems = [];
    },
    toggleAnsweredType: (state, { payload }) => {
      if (payload === state.answeredType) {
        return state;
      }

      state.answeredType = payload;
      state.send = true;
      state.uploaded = false;
      state.token = null;
      state.selectAll = OPTION_DISABLED;
      state.items = [];
      state.mailsCounter = [];
      state.selectedItems = [];
    },
    toggleSend: (state, { payload }) => {
      if (payload === state.send) {
        return state;
      }

      state.send = payload;
    },
    setError: (state, { payload }) => {
      if (!payload) {
        state.error.show = false;
        state.error.message = null;
      } else {
        state.error.show = true;
        state.error.message = payload;
      }
    },
    setDialogCounters: (state, { payload }) => {
      state.mailsCounter = { ...state.mailsCounter, ...payload };
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    toggleSelectAll: (state, { payload }) => {
      if (payload !== state.selectAll) {
        state.selectAll = payload;

        if (state.selectAll === OPTION_ENABLED) {
          setSelectAll(state);
        } else {
          state.selectedItems = [];
        }
      }
    },
    toggleSelectItem: (state, { payload }) => {
      if (payload.status === OPTION_ENABLED) {
        state.selectedItems.push(payload.uid);
      } else {
        const position = state.selectedItems.indexOf(payload.uid);

        if (position >= 0) {
          if (state.selectAll === OPTION_ENABLED) {
            state.selectAll = OPTION_DISABLED;
          }

          state.selectedItems.splice(position, 1);
        }
      }
    },
    deleteItems: (state, { payload }) => {
      if (state.activeTab !== payload.activeTab) {
        return state;
      }

      const newItems = [];

      for (const item of state.items) {
        if (payload.items.indexOf(item.uid) >= 0) {
          if (typeof state.mailsCounter[item.uid] !== "undefined") {
            delete state.mailsCounter[item.uid];
          }

          const position = state.selectedItems.indexOf(item.uid);
          if (position >= 0) {
            state.selectedItems.splice(position, 1);
          }
        } else {
          newItems.push(item);
        }
      }

      state.items = newItems;

      if (!state.selectedItems?.length && state.selectAll === OPTION_ENABLED) {
        state.selectAll = OPTION_DISABLED;
      }
    },
    updateReadStatus: (state, { payload }) => {
      state.items.forEach((item, index) => {
        if (payload.uids.indexOf(item.uid) >= 0) {
          state.items[index].is_new = payload.is_new;
        }
      });
      state.selectedItems = [];
      state.selectAll = OPTION_DISABLED;
    },
    clearItems: () => {
      return initialState;
    },
  },
});

/**
 * Set all items as select if option select all is enabled
 * @param {Object} state State object
 */
const setSelectAll = (state) => {
  if (state.items.length > 0) {
    for (const item of state.items) {
      if (state.selectedItems.indexOf(item.uid) < 0) {
        state.selectedItems.push(item.uid);
      }
    }
  }
};

export const {
  addItems,
  toggleActiveTab,
  toggleSend,
  setError,
  toggleAnsweredType,
  setDialogCounters,
  setToken,
  toggleSelectAll,
  toggleSelectItem,
  deleteItems,
  updateReadStatus,
  clearItems,
} = mailDialogsSlice.actions;

export default mailDialogsSlice.reducer;
