import { TYPE_VIDEO } from "shared/constants";
import {
  CHANGE_MEDIA_PRIVATE,
  CHAT_FILE_UPLOAD_URL,
  DELETE_MEDIA_ITEM,
  GET_LIKED_CONTENT,
  MAIL_FILE_DELETE_URL,
  MAIL_FILE_UPLOAD_URL,
  POST_ADD_LIKE_MEDIA,
  POST_DELETE_LIKE_MEDIA,
  PRIVATE_MEDIA_URL,
  PROFILE_PHOTO_UPLOAD_URL,
  PROFILE_VIDEO_UPLOAD_URL,
  TEST_SPEED_URL,
} from "shared/constants/apiUrls";
import { MEDIA_SOURCE_PROFILE } from "shared/constants/media";

import { get, post } from "./axios";

/**
 * Purchase and get source on media file
 * @param {string} mediaHash Private key of media file
 * @param {string} source Type from where try upload private image. Possible values [MEDIA_SOURCE_PROFILE, MEDIA_SOURCE_COMMUNICATION]
 * @param {null|number} uid Id of record
 * @return {Promise<AxiosResponse<*>>}
 */
export function getPrivateMedia(
  mediaHash,
  source = MEDIA_SOURCE_PROFILE,
  uid = null
) {
  return get(
    `${
      import.meta.env.VITE_APP_API_HOST + PRIVATE_MEDIA_URL + mediaHash
    }/?type=${source}${uid ? "&uid=" + uid : ""}`
  );
}

/**
 * Delete image in owner account
 * @param {Object} params Request params
 * @return {Promise<AxiosResponse<*>>}
 */
export function deleteMediaItem(params) {
  return post(import.meta.env.VITE_APP_API_HOST + DELETE_MEDIA_ITEM, params);
}

/**
 * Delete image attached to mail message
 * @param {Object} params Request params
 * @return {Promise<AxiosResponse<*>>}
 */
export function deleteMailItem(params) {
  return post(import.meta.env.VITE_APP_API_HOST + MAIL_FILE_DELETE_URL, params);
}

/**
 * Change public image to private for owner account
 * @param {Object} params Request params
 * @return {Promise<AxiosResponse<*>>}
 */
export function changeMediaPrivate(params) {
  return post(import.meta.env.VITE_APP_API_HOST + CHANGE_MEDIA_PRIVATE, params);
}

/**
 * Upload media file for chat
 * @param {Object} fileForm Form with file
 * @return {Promise<AxiosResponse<*>>}
 */
export function uploadChatMediaFile(fileForm) {
  // return post(import.meta.env.VITE_APP_API_HOST + CHAT_FILE_UPLOAD_URL, fileForm, {
  //TODO if we use subdomain for upload 50% of uploaded media were broken
  return post(CHAT_FILE_UPLOAD_URL, fileForm, {
    onUploadProgress: (progressEvent) => {
      console.log(
        "Files upload percent",
        progressEvent.loaded / progressEvent.total,
        progressEvent
      );
    },
  });
}

/**
 * Upload media file for mail
 * @param {Object} fileForm Form with file
 * @return {Promise<AxiosResponse<*>>}
 */
export function uploadMailMediaFile(fileForm) {
  // return post(import.meta.env.VITE_APP_API_HOST + MAIL_FILE_UPLOAD_URL, fileForm, {
  //TODO if we use subdomain for upload 50% of uploaded media were broken
  return post(MAIL_FILE_UPLOAD_URL, fileForm, {
    onUploadProgress: (progressEvent) => {
      console.log(
        "Files upload percent",
        progressEvent.loaded / progressEvent.total,
        progressEvent
      );
    },
  });
}

/**
 * Upload profile file
 * @param {Object} fileForm Form with file
 * @param {number} type Type of file
 * @return {Promise<AxiosResponse<*>>}
 */
export function uploadProfileMediaFile(fileForm, type) {
  // return post(
  //   `${import.meta.env.VITE_APP_API_HOST}${
  //     type === TYPE_VIDEO ? PROFILE_VIDEO_UPLOAD_URL : PROFILE_PHOTO_UPLOAD_URL
  //   }`,
  //TODO if we use subdomain for upload 50% of uploaded media were broken
  return post(
    `${
      type === TYPE_VIDEO ? PROFILE_VIDEO_UPLOAD_URL : PROFILE_PHOTO_UPLOAD_URL
    }`,
    fileForm,
    {
      onUploadProgress: (progressEvent) => {
        console.log(
          "Files upload percent",
          progressEvent.loaded / progressEvent.total,
          progressEvent
        );
      },
    }
  );
}

/**
 * Like content
 * @param {Object} params Content uid
 * @returns {Promise<AxiosResponse<*>>}
 */
export const likeContent = (params) => {
  return post(import.meta.env.VITE_APP_API_HOST + POST_ADD_LIKE_MEDIA, params);
};

/**
 * Remove like for photo
 * @param {string} uid Content uid
 * @returns {Promise<AxiosResponse<*>>}
 */
export const removeLikeContent = (uid) => {
  return post(import.meta.env.VITE_APP_API_HOST + POST_DELETE_LIKE_MEDIA + uid);
};

/**
 * Like photo
 * @param {string} profileUid Profile uid
 * @param {Array} uids Uids of photos
 * @returns {Promise<AxiosResponse<*>>}
 */
export const getLikedContent = (profileUid, uids) => {
  const searchParams = new URLSearchParams();

  searchParams.append("profile_uid", profileUid);
  uids.forEach((uid) => searchParams.append("uid[]", uid));

  return get(
    import.meta.env.VITE_APP_API_HOST +
      GET_LIKED_CONTENT +
      `?${searchParams.toString()}`
  );
};

/**
 * Set status for speed test
 * @param {object} data
 * @returns {Promise<AxiosResponse<*>>}
 */
export const setTestSpeedStatus = (data) => {
  return post(import.meta.env.VITE_APP_API_HOST + TEST_SPEED_URL, data);
};
