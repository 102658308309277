import { createSlice } from "@reduxjs/toolkit";

import { APP_DESKTOP, EMAIL_VERIFIED } from "shared/constants";
import { NOTIFY_COOKIE_NAME } from "shared/constants/cookie";

import { existCookie } from "shared/helpers/cookie";

const initialState = {
  show: false,
  showResend: false,
  showTimeout: null,
  showTimeoutAfterHide: null,
};

const confirmEmailNotifySlice = createSlice({
  name: "confirm-email-notify",
  initialState,
  reducers: {
    set: (state, { payload }) => {
      if (
        import.meta.env.VITE_APP_APPLICATION_TYPE === APP_DESKTOP &&
        payload !== EMAIL_VERIFIED &&
        !existCookie(NOTIFY_COOKIE_NAME)
      ) {
        state.show = true;
      }
    },
  },
});

export const { set } = confirmEmailNotifySlice.actions;

export default confirmEmailNotifySlice.reducer;
