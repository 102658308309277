import { createSlice } from "@reduxjs/toolkit";

import {
  IS_OFFLINE,
  IS_ONLINE,
  STREAM_LIVE,
  STREAM_NOT_LIVE,
} from "shared/constants";
import {
  CHANNEL_MEMBER_BANNED_STATUS,
  CHANNEL_STATUS_DELETED,
  CHAT_CHANNEL_TYPE_ALL,
  CHAT_CHANNEL_TYPE_IS_ACTIVE,
  CHAT_MESSAGE_TYPE_TEXT,
} from "shared/constants/chat";

import {
  deleteMessageItemsFromStorage,
  getChannelIdentity,
  getChatChannelUsersIndexes,
  isHasMutualCommunication,
} from "shared/helpers/chat";
import { sortObject } from "shared/helpers/view";

const initialState = {
  userUid: null,
  items: {},
  token: null,
  uploaded: false,
  uploadNew: false,
  send: false,
  searchQuery: null,
  channelType: CHAT_CHANNEL_TYPE_ALL,
  lastActivity: null,
  checkStreamStatus: false,
  checkActiveDropChannel: null,
  dropTemporaryMessages: null,

  error: {
    show: false,
    message: null,
  },
};

const chatChannelsSlice = createSlice({
  name: "chat/channels",
  initialState,
  reducers: {
    setUserUid: (state, { payload }) => {
      if (state.userUid !== payload) {
        state.userUid = payload;
      }
    },
    setChannels: (state, { payload }) => {
      if (
        payload.filters?.query !== state.searchQuery ||
        payload.filters?.type !== state.channelType
      ) {
        return state;
      }
      clearStateAfterChangeFilters(state);
      state.send = false;
      state.uploaded = true;
      state.token = payload?.token ?? null;
      state.error = state.error.show
        ? state.error
        : {
            show: false,
            message: null,
          };

      addItemsToState(state, payload?.items);
    },
    addNewChannel: (state, { payload }) => {
      if (
        payload.filters?.query !== state.searchQuery ||
        payload.filters?.type !== state.channelType
      ) {
        return state;
      }

      addItemsToState(state, [payload?.item]);
    },
    addBannedChannel: (state, { payload }) => {
      addItemsToState(state, [payload]);
    },
    addNewChannelIfNotExist: (state, { payload }) => {
      if (typeof state.items[payload.identity] === "undefined") {
        addItemsToState(state, [payload.item]);
        if (payload.cb) {
          payload.cb();
        }
      }
    },
    addChannels: (state, { payload }) => {
      if (
        payload.filters?.query !== state.searchQuery ||
        payload.filters?.type !== state.channelType
      ) {
        return state;
      }

      addItemsToState(state, payload?.items);
      state.uploaded = true;
      state.token = payload?.token ?? null;
      state.send = false;
      state.uploadNew = false;
      state.error = state.error.show
        ? state.error
        : {
            show: false,
            message: null,
          };
    },
    updateChannels: (state, { payload }) => {
      addItemsToState(state, payload);
    },
    toggleUploadNew: (state, { payload }) => {
      if (state.uploaded && payload) {
        state.uploadNew = payload;
      } else if (!payload) {
        state.uploadNew = payload;
      }
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    toggleSend: (state, { payload }) => {
      state.send = payload;
    },
    setSearchQuery: (state, { payload }) => {
      if (state.searchQuery !== payload) {
        state.searchQuery = payload?.length > 0 ? payload : null;
        state.send = true;
        state.items = {};
        clearStateAfterChangeFilters(state);
      }
    },
    setChannelType: (state, { payload }) => {
      if (state.channelType !== payload) {
        state.channelType = payload;
        state.send = true;
        state.items = {};
        clearStateAfterChangeFilters(state);
      }
    },
    setError: (state, { payload }) => {
      state.error = {
        show: true,
        message: payload.text,
      };
    },
    clearError: (state) => {
      if (state.error.show) {
        state.error = {
          show: false,
          message: null,
        };
      }
    },
    updateOnlineStatus: (state, { payload }) => {
      if (!payload || Object.keys(payload).length === 0) {
        return state;
      }

      Object.keys(payload).forEach((uid) => {
        const status = payload[uid] ? IS_ONLINE : IS_OFFLINE;
        const identity = getChannelIdentity([uid, state.userUid]);

        if (
          typeof state.items[identity] !== "undefined" &&
          state.items[identity].user.is_online !== status
        ) {
          state.items[identity].user.is_online = status;
        }
      });
    },

    updateStreamStatus: (state, { payload }) => {
      if (!payload || Object.keys(payload).length === 0) {
        return state;
      }

      Object.keys(payload).forEach((uid) => {
        const status = payload[uid] ? STREAM_LIVE : STREAM_NOT_LIVE;
        const identity = getChannelIdentity([uid, state.userUid]);

        if (
          typeof state.items[identity] !== "undefined" &&
          state.items[identity].user.in_stream !== status
        ) {
          state.items[identity].user.in_stream = status;
        }
      });
    },

    toggleCheckStreamStatus: (state, { payload }) => {
      if (state.checkStreamStatus !== payload) {
        state.checkStreamStatus = payload;
      }
    },
    updateTyping: (state, { payload }) => {
      if (
        typeof state.items[payload.identity] !== "undefined" &&
        state.items[payload.identity].is_typing !== payload.status
      ) {
        state.items[payload.identity].is_typing = payload.status;
      }
    },
    togglePinSend: (state, { payload }) => {
      if (
        typeof state.items[payload.identity] !== "undefined" &&
        state.items[payload.identity].sendPin !== payload.send
      ) {
        state.items[payload.identity].sendPin = payload.send;
      }
    },
    toggleBanChannel: (state, { payload }) => {
      const identity = getChannelIdentity([payload.uid, state.userUid]);

      if (typeof state.items[identity] !== "undefined") {
        state.items[identity].isBanned = payload.status;
      }
    },
    deleteChannel: (state, { payload }) => {
      if (typeof state.items[payload] !== "undefined") {
        delete state.items[payload];
        state.checkActiveDropChannel = payload;
      }
    },
    deleteBannedChannel: (state, { payload }) => {
      if (
        typeof state.items[payload] !== "undefined" &&
        state.items[payload].isBanned
      ) {
        delete state.items[payload];
        state.checkActiveDropChannel = payload;
      }
    },
    updateCheckActiveChannel: (state, { payload }) => {
      if (state.checkActiveDropChannel !== payload) {
        state.checkActiveDropChannel = payload;
      }
    },
    updateTempAndEmptyText: (state, { payload }) => {
      if (
        state.items[payload.identity]?.isTemp &&
        state.items[payload.identity]?.isTempAndEmptyText !== payload.empty
      ) {
        state.items[payload.identity].isTempAndEmptyText = payload.empty;
      }
    },
    deleteTemporaryChannels: (state, { payload }) => {
      if (Object.keys(state.items).length > 0) {
        const items = {};

        for (const identity in state.items) {
          if (!Object.prototype.hasOwnProperty.call(state.items, identity)) {
            continue;
          }
          const item = state.items[identity];

          if (!item.isTempAndEmptyText) {
            items[item.identity] = item;
          } else {
            deleteMessageItemsFromStorage(item.identity);

            if (payload.activeChat === item.identity) {
              state.dropTemporaryMessages = item.identity;
            }
          }
        }

        if (Object.keys(state.items).length !== Object.keys(items).length) {
          state.items = items;
        }
      }
    },
    updateDropTempMessages: (state, { payload }) => {
      if (state.dropTemporaryMessages !== payload) {
        state.dropTemporaryMessages = payload;
      }
    },
  },
});

/**
 * Cleare state before change filters
 * @param {Object} state Storage state
 */
const clearStateAfterChangeFilters = (state) => {
  state.uploaded = false;
  state.uploadNew = false;
  state.token = null;
  state.lastActivity = null;
};

/**
 *
 * @param {Object} state State object
 * @param {Array} items New channel items
 */
const addItemsToState = (state, items) => {
  if (!items || Object.keys(items).length === 0) {
    return;
  }

  const pinned = {};
  let channels = JSON.parse(JSON.stringify(state.items));

  for (
    let iteration = 0;
    iteration < Object.keys(channels).length;
    iteration++
  ) {
    const channel = channels[Object.keys(channels)[iteration]];

    if (!channel?.user?.pinned) {
      break;
    }

    pinned[channel.identity] = channel;
  }

  for (const item of items) {
    const channel = getChannelFromInstance(state, item);

    if (
      !channel.identity ||
      //Condition for Active tab
      (state.channelType === CHAT_CHANNEL_TYPE_IS_ACTIVE &&
        !isHasMutualCommunication(channel))
    ) {
      continue;
    }

    if (channel?.user?.pinned) {
      if (channels[channel?.identity]) {
        delete channels[channel?.identity];
      }

      pinned[channel.identity] = channel;
    } else {
      if (pinned[channel.identity]) {
        delete pinned[channel.identity];
      }
      channels[channel.identity] = channel;
    }
  }

  channels = sortObject(channels, "lastActivity", true);

  const keys = Object.keys(channels);
  const lastActivity =
    keys[0] && channels[keys[0]]?.lastActivity
      ? channels[keys[0]]?.lastActivity
      : state.lastActivity;

  state.items = { ...pinned, ...channels };

  if (state.lastActivity !== lastActivity) {
    state.lastActivity = lastActivity;
  }
};

/**
 * Prepare channel instance
 * @param {Object} state State object
 * @param {Object} channel Channel object
 * @return {{countNew: number, identity: *, message: {createdAt: number, isDelivered: boolean, isConsumed: boolean, media: (*|*[]), body: (*|string), type: (*|number)}, user: {pinned: boolean, gender: *, avatar: *, is_online: number, first_name: *}}}
 */
const getChannelFromInstance = (state, channel) => {
  const hasOpponentIndex =
    typeof channel.opponentIndex !== "undefined" &&
    channel.opponentIndex !== null;

  const users = {
    user: hasOpponentIndex
      ? channel?.members[channel.opponentIndex > 0 ? 0 : 1]
      : null,
    opponent: hasOpponentIndex ? channel?.members[channel.opponentIndex] : null,
  };

  const messages = {
    user: hasOpponentIndex
      ? channel?.message[channel.opponentIndex > 0 ? 0 : 1]
      : null,
    opponent: hasOpponentIndex ? channel?.message[channel.opponentIndex] : null,
  };

  if (!users.user || !users.opponent) {
    const indexes = getChatChannelUsersIndexes(channel?.members, state.userUid);

    if (indexes.user !== null) {
      users.user = channel?.members[indexes.user];
      messages.user = channel?.message[indexes.user];
      users.opponent = channel?.members[indexes.opponent];
      messages.opponent = channel?.message[indexes.opponent];
    }
  }

  const isDeleted = +users?.user?.status === CHANNEL_STATUS_DELETED;
  const isBanned = +users?.opponent?.status === CHANNEL_MEMBER_BANNED_STATUS;

  return {
    identity: channel?.identity,
    index: channel?.index ?? 0,
    lastConsumeIndex: messages?.user?.lastConsumeIndex ?? 0,
    isDisabled: users?.opponent?.isDisabled,
    user: {
      uid: users?.opponent?.uid,
      gender: users?.opponent?.gender,
      first_name: users?.opponent?.first_name,
      avatar: users?.opponent?.avatar,
      is_online: state.items[channel?.identity]
        ? state.items[channel?.identity].user.is_online
        : IS_OFFLINE,
      in_stream: state.items[channel?.identity]
        ? state.items[channel?.identity].user.in_stream
        : STREAM_NOT_LIVE,
      pinned: !!users?.user?.pinned,
    },
    is_typing: state.items[channel?.identity]
      ? state.items[channel?.identity].is_typing
      : false,
    countNew:
      isDeleted || isBanned
        ? 0
        : getCountNewMessages(messages.user, channel?.index),
    message: getChannelLastMessage(messages.user, messages.opponent),
    lastActivity: channel?.lastActivity,
    sendPin: false,
    //Current channel is temporary and doesn't add to DB
    isTemp: !!channel?.isTemp,
    //Current channel is temporary and user doesn't type any text in chat input field
    isTempAndEmptyText: !!channel?.isTemp,
    isBanned,
    isDeleted,
    members: channel?.members,
    //User and opponent initiate communication
    isConnected:
      channel?.members[0]?.writeNotWink && channel?.members[1]?.writeNotWink,
  };
};

/**
 * Get channel count new messages
 * @param {Object} message Message object
 * @param {number} index Channel index
 * @return {number}
 */
const getCountNewMessages = (message, index) => {
  if (!message || !index) {
    return 0;
  }

  const lastConsumeIndex = message?.lastConsumeIndex ?? 0;

  if (+index < +lastConsumeIndex) {
    return 0;
  }

  return +index - +lastConsumeIndex;
};

/**
 * Get last channel message
 * @param {Object} userMessage User message object
 * @param {Object} opponentMessage Opponent message object
 * @return {{createdAt: number, isDelivered: boolean, isConsumed: boolean, media: (*|*[]), body: (*|string), type: (*|number)}}
 */
const getChannelLastMessage = (userMessage, opponentMessage) => {
  let message = {};

  if (!userMessage?.createdAt && opponentMessage?.createdAt) {
    message = opponentMessage;
  } else if (userMessage?.createdAt && !opponentMessage?.createdAt) {
    message = userMessage;
  } else if (userMessage?.createdAt && opponentMessage?.createdAt) {
    message =
      +userMessage?.createdAt > +opponentMessage?.createdAt
        ? userMessage
        : opponentMessage;
  }

  const isSelfText = message.uid && message.uid !== userMessage.uid;
  const isConsumed =
    !message || (isSelfText && message?.index <= message?.lastConsumeIndex);
  const isDelivered = !message || (!isConsumed && isSelfText);

  return {
    uid: isSelfText ? userMessage.uid : opponentMessage.uid,
    body: message?.body ?? "",
    media: message?.media ?? [],
    type: message?.type ?? CHAT_MESSAGE_TYPE_TEXT,
    createdAt: message?.createdAt ?? null,
    isConsumed,
    isDelivered,
  };
};

export const {
  setChannels,
  setUserUid,
  addChannels,
  updateChannels,
  toggleUploadNew,
  setToken,
  toggleSend,
  setChannelType,
  setSearchQuery,
  setError,
  clearError,
  updateOnlineStatus,
  updateStreamStatus,
  toggleCheckStreamStatus,
  updateTyping,
  addNewChannel,
  addBannedChannel,
  togglePinSend,
  toggleBanChannel,
  deleteChannel,
  deleteBannedChannel,
  deleteTemporaryChannels,
  updateCheckActiveChannel,
  addNewChannelIfNotExist,
  updateTempAndEmptyText,
  updateDropTempMessages,
} = chatChannelsSlice.actions;

export default chatChannelsSlice.reducer;
