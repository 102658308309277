import { addHours, isWithinInterval } from "date-fns";

import { EMAIL_VERIFIED } from "shared/constants";
import {
  ALL_FEATURE_MODAL_OPENED_COOKIE_KEY,
  BLACK_FRIDAY_MODAL_OPENED_COOKIE_KEY,
  CO_REG_COOKIE_KEY,
  EVENT_DISC_COOKIE_KEY,
  HALLOWEEN_MODAL_OPENED_COOKIE_KEY,
  HAPPY_BIRTHDAY_MODAL_OPENED_COOKIE_KEY,
  MAIL_CONFIRMATION_COOKIE_KEY,
  ONE_TIME_DISC_COOKIE_KEY,
  ONE_TIME_DISC_MAIL_COOKIE_KEY,
  WEB_PUSH_SUBSCRIBE_DISC_COOKIE_KEY,
} from "shared/constants/cookie";
import {
  FEED_UPDATE_TYPE,
  VIRTUAL_GIFTS_TYPE,
  WELCOME_POPUP_TYPE,
} from "shared/constants/onboarding";
import { PROFILE_PURCHASE_TYPE_FREE } from "shared/constants/profile";
import { APP_VERSION_V2 } from "shared/constants/user";

import { existCookie, getCookie, setCookie } from "./cookie";
import { isPushAvailable, isTrue } from "./view";

/**
 * Need to show email confirm modal
 * @param {Object} profile Current user object
 * @returns {boolean}
 */
export const isAvailableMailConfirmationNew = (profile) => {
  return (
    profile?.disc?.mail?.enabled &&
    profile?.disc?.mail?.showFirst &&
    !existCookie(ONE_TIME_DISC_MAIL_COOKIE_KEY)
  );
};

/**
 * Need to show email success confirm modal
 * @param {Object} profile Current user object
 * @returns {boolean}
 */
export const isAvailableMailSuccessConfirmationNew = (profile) => {
  return profile?.disc?.mail?.enabled && profile?.disc?.mail?.showSuccess;
};

/**
 * @param {Object} profile Current user object
 * @returns {boolean}
 */
export const isAvailableMailConfirmation = (profile) => {
  return (
    profile?.email_verification_status !== EMAIL_VERIFIED &&
    !existCookie(ONE_TIME_DISC_MAIL_COOKIE_KEY) &&
    !existCookie(MAIL_CONFIRMATION_COOKIE_KEY)
  );
};

/**
 * Check is available free credits congrats modal
 *
 * @param {Object} profile Current user object
 * @return {boolean}
 */
export const isAvailableFreeCreditsCongrats = (profile) => {
  return !!profile?.disc?.reg?.enabled;
};

/**
 * Check is available complete registration
 *
 * @param {Object} profile Current user object
 * @return {boolean}
 */
export const isAvailableCompleteRegistration = (profile) => {
  return !profile?.is_completed;
};

/**
 * Check is available co-registration
 *
 * @param {Object} profile Current user object
 * @return {boolean}
 */
export const isAvailableCoRegistration = (profile) => {
  return (
    !existCookie(CO_REG_COOKIE_KEY) && !!profile?.disc?.remarketing?.enabled
  );
};

/**
 * Check is available mail discount success modal
 *
 * @param {Object} profile Current user object
 * @return {boolean}
 */
export const isAvailableMailDiscountSuccess = (profile) => {
  return !!profile?.disc?.mail?.showSuccess;
};

/**
 * Check is available mail discount first time modal
 *
 * @param {Object} profile Current user object
 * @return {boolean}
 */
export const isAvailableMailDiscountFirstTime = (profile) => {
  return !!profile?.disc?.mail?.showFirst && !!profile?.disc?.mail?.enabled;
};

/**
 * Check is available mail discount first time modal
 *
 * @param {Object} profile Current user object
 * @return {boolean}
 */
export const isAvailableOneTimeDiscount = (profile) => {
  return (
    !!profile?.disc?.oneTime?.enabled && !existCookie(ONE_TIME_DISC_COOKIE_KEY)
  );
};

/**
 * Check is available gift 50% discount modal
 *
 * @param {Object} profile Current user object
 * @return {boolean}
 */
export const isAvailableGift50percentDiscount = (profile) => {
  if (
    !!profile?.disc?.oneTime?.enabled &&
    !existCookie(ONE_TIME_DISC_COOKIE_KEY)
  ) {
    return false;
  }
  return !!profile?.disc?.halfPrice?.enabled;
};

/**
 * Check is available mail discount first time modal
 *
 * @param {Object} profile Current user object
 * @return {boolean}
 */
export const isAvailableAutoLoginDiscountSuccessModal = (profile) => {
  return !!profile?.disc?.autoLogin?.showSuccess;
};

/**
 * Check is available mail discount first time modal
 *
 * @param {Object} profile Current user object
 * @return {boolean}
 */
export const isAvailablePushSubscribeModal = (profile) => {
  const dickCookie = getCookie(WEB_PUSH_SUBSCRIBE_DISC_COOKIE_KEY);
  return (
    !dickCookie && isPushAvailable(true) && !!profile?.disc?.webPushSub?.enabled
  );
};

/**
 * Check is available event discount modal
 *
 * @param {Object} profile Current user object
 * @return {boolean}
 */
export const isAvailableEventDiscountModal = (profile) => {
  return !existCookie(EVENT_DISC_COOKIE_KEY) && !!profile?.disc?.hel?.enabled;
};

/**
 * Check is available new design modal
 *
 * @param {Object} profile Current user object
 * @return {boolean}
 */
export const isAvailableNewDesign = (profile) => {
  return profile.app_version !== APP_VERSION_V2;
};

/**
 * Need to show reactivation modal
 * @param {Object} profile Current user object
 * @returns {boolean}
 */
export const isAvailableReactivation = (profile) => {
  return profile?.discounts.reactivation.enabled;
};

/**
 * Need to show Halloween modal
 * @param {Object} profile Current user object
 * @returns {boolean}
 */
export const isAvailableHalloweenModal = (profile) => {
  return (
    !existCookie(HALLOWEEN_MODAL_OPENED_COOKIE_KEY) &&
    profile?.discounts.halloween.enabled
  );
};

/**
 * Need to show Black Friday modal
 * @param {Object} profile Current user object
 * @returns {boolean}
 */
export const isAvailableBlackFridayModal = (profile) => {
  return (
    !existCookie(BLACK_FRIDAY_MODAL_OPENED_COOKIE_KEY) &&
    profile?.discounts.blackFriday.enabled
  );
};

/**
 * Need to show birthday modal
 * @param {Object} profile Current user object
 * @returns {boolean}
 */
export const isAvailableBirthdayModal = (profile) => {
  const isEnabled = isTrue(
    import.meta.env.VITE_APP_EVENT_FEATURE_BIRTHDAY_MODAL
  );

  const now = new Date();
  const birthdayDate = new Date(profile?.birthday);
  birthdayDate.setFullYear(now.getFullYear());
  const birthdayEnding = addHours(birthdayDate, 72);

  return (
    isEnabled &&
    !window.holidayEvent &&
    profile?.discounts.birthday.enabled &&
    !existCookie(HAPPY_BIRTHDAY_MODAL_OPENED_COOKIE_KEY) &&
    isWithinInterval(now, {
      start: birthdayDate,
      end: birthdayEnding,
    })
  );
};

export const isAvailableAllFeaturesModal = (profile) => {
  const isFreeUser =
    Number(profile?.purchase_type) === PROFILE_PURCHASE_TYPE_FREE;
  const isExistsCookie = existCookie(ALL_FEATURE_MODAL_OPENED_COOKIE_KEY);
  const isExistsSession = sessionStorage.getItem(
    ALL_FEATURE_MODAL_OPENED_COOKIE_KEY
  );

  if (!isFreeUser || isExistsSession === "showed") {
    return false;
  }

  if (!isExistsSession && isFreeUser && !isExistsCookie) {
    sessionStorage.setItem(ALL_FEATURE_MODAL_OPENED_COOKIE_KEY, "showed");
    setCookie(ALL_FEATURE_MODAL_OPENED_COOKIE_KEY, true, 364, true, null);
    return false;
  }

  if (
    isExistsCookie &&
    !isExistsSession &&
    !profile?.discounts.birthday.enabled
  ) {
    sessionStorage.setItem(ALL_FEATURE_MODAL_OPENED_COOKIE_KEY, "showed");
    return true;
  }

  return false;
};

/**
 * Need to show new virtual gifts modal
 * @param {Object} onboardings Current user onboardings
 * @returns {boolean}
 */
export const isAvailableNewVirtualGiftsModal = (onboardings) => {
  const isEnabled = isTrue(import.meta.env.VITE_APP_FEATURE_GIFTS);

  return isEnabled && onboardings[VIRTUAL_GIFTS_TYPE].active;
};

/**
 * Show welcome modal
 * @param {Object} onboardings Current user onboardings
 * @returns {boolean}
 */
export const isAvailableWelcomeModal = (onboardings) => {
  return onboardings[WELCOME_POPUP_TYPE].active;
};

/**
 * Show feed update modal
 * @param {Object} onboardings Current user onboardings
 * @returns {boolean}
 */
export const isAvailableFeedUpdateModal = (onboardings) => {
  const isFeedUpdateShowed = Boolean(sessionStorage.getItem(FEED_UPDATE_TYPE));

  return onboardings[FEED_UPDATE_TYPE].active && !isFeedUpdateShowed;
};
