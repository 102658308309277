import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  ask_for_photo: 0,
  ask_for_video: 0,
  chat_message: 0,
  chat_minute: 0,
  dialog_view: 0,
  gift: 0,
  long_search: 0,
  mail: 0,
  private_photo: 0,
  private_video: 0,
  private_video_profile: 0,
  private_video_communication: 0,
  photo_upload_chat: 0,
  video_upload_chat: 0,
  stream: 0,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    set: (state, { payload }) => {
      return payload;
    },
  },
});

export const { set } = settingsSlice.actions;

export default settingsSlice.reducer;
