import activity from "./slices/activity";
import banners from "./slices/banners";
import blockedUsersSlice from "./slices/blockedUser";
import chat from "./slices/chat";
import chatChannels from "./slices/chat/channels";
import chatFiles from "./slices/chat/files";
import chatMessages from "./slices/chat/messages";
import requestedContacts from "./slices/contacts";
import contest from "./slices/contest";
import criteria from "./slices/criteria";
import favorites from "./slices/favorites";
import gifts from "./slices/gifts";
import popularGifts from "./slices/gifts/popular";
import holidayEvent from "./slices/holidayEvent";
import activeCountries from "./slices/location/activeCountries";
import city from "./slices/location/city";
import country from "./slices/location/country";
import mailDialogs from "./slices/mail/dialogs";
import mailFiles from "./slices/mail/files";
import mailMessages from "./slices/mail/messages";
import mailConfirm from "./slices/mail-confirm";
import mediaGallery from "./slices/media-gallery";
import modalQueue from "./slices/modal-queue";
import news from "./slices/news";
import userNotifyCounter from "./slices/notify/counters";
import confirmEmailNotify from "./slices/notify/email-confirm";
import notifySocket from "./slices/notify/socket";
import onboarding from "./slices/onboarding";
import payment from "./slices/payment";
import profile from "./slices/profile";
import profileBilling from "./slices/profile/billing";
import profileFiles from "./slices/profile/files";
import purchase from "./slices/profile/purchase";
import settingsProfile from "./slices/profile/settings";
import profileSimple from "./slices/profile/simple";
import profileUpdate from "./slices/profile/update";
import route from "./slices/route";
import search from "./slices/search";
import searchFilter from "./slices/search/filter";
import settings from "./slices/settings";
import stream from "./slices/stream";
import user from "./slices/user";

const reducers = {
  activeCountries,
  activity,
  banners,
  blockedUsersSlice,
  chat,
  chatChannels,
  chatFiles,
  chatMessages,
  city,
  confirmEmailNotify,
  contest,
  country,
  criteria,
  favorites,
  gifts,
  holidayEvent,
  mailConfirm,
  mailDialogs,
  mailFiles,
  mailMessages,
  mediaGallery,
  modalQueue,
  news,
  notifySocket,
  payment,
  popularGifts,
  profile,
  profileBilling,
  profileFiles,
  profileSimple,
  profileUpdate,
  purchase,
  requestedContacts,
  route,
  search,
  searchFilter,
  settings,
  settingsProfile,
  stream,
  user,
  userNotifyCounter,
  onboarding,
};

export default reducers;
