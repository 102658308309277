export const REASON_DESCRIPTION_MAX_LENGTH = 200;
export const REPORT_REASON_SCAM = 1;
export const REPORT_REASON_HARASSMENT = 2;
export const REPORT_REASON_FAKE_ACCOUNT = 3;
export const REPORT_REASON_INAPPROPRIATE = 4;
export const REPORT_REASON_OTHER = 5;

export const REAL_PRESENTS_ENABLED = 1;

export const ABUSE_REASONS = {
  [REPORT_REASON_SCAM]: "scam",
  [REPORT_REASON_HARASSMENT]: "harassment",
  [REPORT_REASON_FAKE_ACCOUNT]: "fakeAccount",
  [REPORT_REASON_INAPPROPRIATE]: "inappropriate",
  [REPORT_REASON_OTHER]: "other",
};

export const BILLING_TYPE_CHAT_PER_MINUTE = 1;

export const APP_VERSION_V2 = 2;
