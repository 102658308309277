import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showTopActivity: false,
  showNewActivity: false,
};

const mailConfirmSlice = createSlice({
  name: "mail-confirm",
  initialState,
  reducers: {
    set: (state, { payload }) => {
      if (state.showTopActivity !== payload) {
        state.showTopActivity = !!payload;
      }
    },
    setNew: (state, { payload }) => {
      if (state.showNewActivity !== payload) {
        state.showNewActivity = !!payload;
      }
    },
  },
});

export const { set, setNew } = mailConfirmSlice.actions;

export default mailConfirmSlice.reducer;
