import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  realGifts: {
    show: false,
    active: false,
  },
  virtualGifts: {
    show: false,
    active: false,
  },
  welcomePopup: {
    show: false,
    active: false,
  },
  gallerySwipe: {
    show: false,
    active: false,
  },
  feedUpdate: {
    show: false,
    active: false,
  },
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setOnboarding: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

export const { setOnboarding, setLoading } = onboardingSlice.actions;

export default onboardingSlice.reducer;
