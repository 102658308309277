import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  height: [],
  weight: [],
  body_type: [],
  marital_status: [],
  children: [],
  more_children: [],
  education_level: [],
  religion: [],
  drink: [],
  smoke: [],
  age: {},
  join_reasons: [],
  has_children: [],
};

const criteriaSlice = createSlice({
  name: "criteria",
  initialState,
  reducers: {
    setCriteria: (state, { payload }) => payload,
  },
});

export const { setCriteria } = criteriaSlice.actions;

export default criteriaSlice.reducer;
