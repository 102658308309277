import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "shared/redux/appReducers";

const excludeSerializableCheck = {
  serializableCheck: false,
};

const store = configureStore({
  reducer: rootReducer,
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(excludeSerializableCheck),
});

export default store;
