import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uid: 0,
  gifts_order_new: 0,
  match_count_new: 0,
  news_count_new: 0,
  activities_count_new: 0,
  mails_count_new: 0,
  mails_count_total: 0,
  chat_count_new: 0,
  created_at_m: 0,
};

const userNotifyCounterSlice = createSlice({
  name: "user/notify/counter",
  initialState,
  reducers: {
    updateCounters: (state, { payload }) => {
      if (payload && payload.created_at_m >= state.created_at_m) {
        Object.keys(initialState).forEach((attribute) => {
          if (
            payload[attribute] >= 0 &&
            +state[attribute] !== +payload[attribute]
          ) {
            state[attribute] = +payload[attribute];
          }
        });
      }
    },
    updateChatCounter: (state, { payload }) => {
      if (state.chat_count_new !== +payload) {
        state.chat_count_new = +payload;
      }
    },
    updateNewsCounter: (state, { payload }) => {
      if (state.news_count_new !== +payload) {
        state.news_count_new = +payload;
      }
    },
    updateActivityCounter: (state, { payload }) => {
      if (state.activities_count_new !== +payload) {
        state.activities_count_new = +payload;
      }
    },
  },
});

export const {
  updateCounters,
  updateChatCounter,
  updateNewsCounter,
  updateActivityCounter,
} = userNotifyCounterSlice.actions;

export default userNotifyCounterSlice.reducer;
