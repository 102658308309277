import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  blockedCount: 0,
  send: false,
  status: null,
  newPassword: "",
  in_app_sound: "",
  isDeleted: false,
};

const settingsSlice = createSlice({
  name: "profile/settings",
  initialState,
  reducers: {
    setBlockedUser: (state, { payload }) => {
      return {
        ...state,
        blockedCount: payload,
      };
    },
    setStatusVerified: (state, { payload }) => {
      return {
        ...state,
        status: payload,
      };
    },
    toggleSendProfile: (state, { payload }) => {
      state.send = payload;
    },
    setPassword: (state, { payload }) => {
      state.newPassword = payload;
    },
    setUserDeleted: (state) => {
      state.isDeleted = true;
    },
    setSoundSettings: (state, { payload }) => {
      return {
        ...state,
        in_app_sound: payload,
      };
    },
  },
});

export const {
  setBlockedUser,
  toggleSendProfile,
  setStatusVerified,
  setPassword,
  setSoundSettings,
  setUserDeleted,
} = settingsSlice.actions;
export default settingsSlice.reducer;
