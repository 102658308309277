import { matchRoutes } from "react-router-dom";
import { createSlice } from "@reduxjs/toolkit";

import {
  isAvailableAllFeaturesModal,
  isAvailableBirthdayModal,
  isAvailableBlackFridayModal,
  isAvailableCoRegistration,
  isAvailableFeedUpdateModal,
  isAvailableFreeCreditsCongrats,
  isAvailableHalloweenModal,
  isAvailableMailConfirmation,
  isAvailableMailConfirmationNew,
  isAvailableMailSuccessConfirmationNew,
  isAvailableNewDesign,
  isAvailableNewVirtualGiftsModal,
  isAvailableReactivation,
  isAvailableWelcomeModal,
} from "shared/helpers/queue";

const initialState = {
  hasActiveModal: false,
  activeModalKey: null,
  queue: [],
  inited: false,
  // The high priority of execute has first modal and descending
  modals: {
    newDesign: {
      key: "newDesign",
      show: false,
      timeout: 1,
      timeoutAfterPrev: 1,
    },
    allFeatures: {
      key: "allFeatures",
      show: false,
      timeout: 5,
      timeoutAfterPrev: 5,
    },
    new_virtual_gifts: {
      key: "new_virtual_gifts",
      show: false,
      timeout: 5,
      timeoutAfterPrev: 1,
      condition: (location) => {
        const allowedRoutes = [
          { path: "/app/user/:uid" },
          { path: "/app/chat/:chatMemberUid" },
        ];

        return matchRoutes(allowedRoutes, location);
      },
    },
    coRegistration: {
      key: "coRegistration",
      show: false,
      timeout: 1,
      timeoutAfterPrev: 60,
    },
    halloween: {
      key: "halloween",
      show: false,
      timeout: 1,
      timeoutAfterPrev: 1,
    },
    confirmationMailSend: {
      key: "confirmationMailSend",
      show: false,
      timeout: 1,
      timeoutAfterPrev: 1,
    },
    blackFriday: {
      key: "blackFriday",
      show: false,
      timeout: 1,
      timeoutAfterPrev: 1,
    },
    completeRegistration: {
      key: "completeRegistration",
      show: false,
    },
    mailDiscountFirstTime: {
      key: "mailDiscountFirstTime",
      show: false,
      timeout: 2,
      timeoutAfterPrev: 5,
    },
    oneTimeDiscount: {
      key: "oneTimeDiscount",
      show: false,
      timeout: 2,
      timeoutAfterPrev: 1,
    },
    congrats20free: {
      key: "congrats20free",
      show: false,
      timeout: 2,
      timeoutAfterPrev: 1,
    },
    birthday: {
      key: "birthday",
      show: false,
      timeout: 20,
      timeoutAfterPrev: 60,
      condition: (location) => {
        const allowedRoutes = [
          { path: "/app/user/:uid" },
          { path: "/app/profile" },
          { path: "/app/news" },
          { path: "/app/" },
        ];

        return matchRoutes(allowedRoutes, location);
      },
    },
    reactivation: {
      key: "reactivation",
      show: false,
      timeout: 2,
      timeoutAfterPrev: 1,
    },
    gift50percentDiscount: {
      key: "gift50percentDiscount",
      show: false,
      timeout: 2,
      timeoutAfterPrev: 1,
    },
    mailConfirmation: {
      key: "mailConfirmation",
      show: false,
      timeout: 1,
      timeoutAfterPrev: 1,
    },
    mailConfirmationNew: {
      key: "mailConfirmationNew",
      show: false,
      timeout: 1,
      timeoutAfterPrev: 1,
    },
    mailDiscountSuccess: {
      key: "mailDiscountSuccess",
      show: false,
      timeout: 2,
      timeoutAfterPrev: 5,
    },
    autoLoginDiscount: {
      key: "autoLoginDiscount",
      show: false,
      timeoutAfterPrev: 5,
    },
    pushSubscribe: {
      key: "pushSubscribe",
      show: false,
      timeoutAfterPrev: 10,
    },
    // pushSubscribeSuccess: {
    //     key: 'pushSubscribeSuccess',
    //     show: false
    // },
    eventDiscount: {
      key: "eventDiscount",
      show: false,
      timeoutAfterPrev: 60,
      timeout: 2,
    },
    welcome: {
      key: "welcome",
      show: false,
      timeout: 1,
      timeoutAfterPrev: 1,
    },
    feedUpdate: {
      key: "feedUpdate",
      show: false,
      timeout: 1,
      timeoutAfterPrev: 1,
      condition: (location) => {
        const allowedRoutes = [{ path: "/app/news" }];

        return matchRoutes(allowedRoutes, location);
      },
    },
  },
  timeout: null,
};

const modalQueueSlice = createSlice({
  name: "modal-queue",
  initialState,
  reducers: {
    init: (state, { payload }) => {
      state.queue = iniQueues(payload.profile, payload.onboardings);
      state.inited = true;
      return nextQueue(state, payload.dispatch, false);
    },
    next: (state, { payload }) => {
      state.queue = removeModalFromQueue(state, payload.key);
      return nextQueue(
        state,
        payload.dispatch,
        true,
        state.activeModalKey === "coRegistration" ? 60 : 0
      );
    },
    addFirstModal: (state, { payload }) => {
      state.queue = resortModalsQueue(state, payload.key);
      return nextQueue(
        state,
        payload.dispatch,
        false,
        state.activeModalKey === "coRegistration" ? 60 : 0
      );
    },
    setAfterTimeout: (state, { payload }) => {
      if (state.activeModalKey === payload && state.modals[payload]) {
        state.modals[payload].show = true;

        if (state.timeout !== null) {
          clearTimeout(state.timeout);
          state.timeout = null;
        }
      }
    },
    showManual: (state, { payload }) => {
      if (state.modals[payload.key]) {
        const queue = JSON.parse(JSON.stringify(state.modals[payload.key]));
        state.modals[payload.key].show = true;
        queue.timeout = 0;
        state.queue.push(queue);
        state.activeModalKey = payload.key;
      }
    },
  },
});

/**
 * Init queue for all modals
 * @param {Object} profile Current user profile object
 * @param {Object} onboardings Current user profile onboardings
 * @returns {[]}
 */
const iniQueues = (profile, onboardings) => {
  const queues = [];

  if (isAvailableNewDesign(profile)) {
    queues.push(initialState.modals.newDesign);
  }

  if (isAvailableCoRegistration(profile)) {
    queues.push(initialState.modals.coRegistration);
  }

  if (isAvailableMailConfirmationNew(profile)) {
    queues.push(initialState.modals.mailConfirmationNew);
  }

  if (
    !isAvailableMailConfirmationNew(profile) &&
    isAvailableMailConfirmation(profile)
  ) {
    queues.push(initialState.modals.mailConfirmation);
  }

  if (
    isAvailableMailSuccessConfirmationNew(profile) ||
    isAvailableFreeCreditsCongrats(profile)
  ) {
    queues.push(initialState.modals.congrats20free);
  }

  if (isAvailableWelcomeModal(onboardings)) {
    queues.push(initialState.modals.welcome);
  }

  if (isAvailableHalloweenModal(profile)) {
    queues.push(initialState.modals.halloween);
  }

  if (isAvailableBlackFridayModal(profile)) {
    queues.push(initialState.modals.blackFriday);
  }

  // if (isAvailableCompleteRegistration(profile)) {
  //     queue.push(initialState.modals.completeRegistration);
  // }
  //
  //

  if (isAvailableBirthdayModal(profile)) {
    queues.push(initialState.modals.birthday);
  }

  if (isAvailableAllFeaturesModal(profile)) {
    queues.push(initialState.modals.allFeatures);
  }

  if (isAvailableNewVirtualGiftsModal(onboardings)) {
    queues.push(initialState.modals.new_virtual_gifts);
  }

  if (isAvailableFeedUpdateModal(onboardings)) {
    queues.push(initialState.modals.feedUpdate);
  }

  if (isAvailableReactivation(profile)) {
    queues.push(initialState.modals.reactivation);
  }

  //
  // if (isAvailableOneTimeDiscount(profile)) {
  //     queue.push(initialState.modals.oneTimeDiscount);
  // }
  //
  // if (isAvailableMailDiscountFirstTime(profile)) {
  //     queue.push(initialState.modals.mailDiscountFirstTime);
  // }
  //
  // if (isAvailableMailDiscountSuccess(profile)) {
  //     queue.push(initialState.modals.mailDiscountSuccess);
  // }

  // if (isAvailableEventDiscountModal(profile)) {
  //   queue.push(initialState.modals.eventDiscount);
  // }

  // if (isAvailableGift50percentDiscount(profile)) {
  //     queue.push(initialState.modals.gift50percentDiscount);
  // }
  //
  // if (isAvailableAutoLoginDiscountSuccessModal(profile)) {
  //     queue.push(initialState.modals.autoLoginDiscount);
  // }
  //
  // if (isAvailablePushSubscribeModal(profile)) {
  //     queue.push(initialState.modals.pushSubscribe);
  // }

  // if (isAvailableEventDiscountModal(profile)) {
  //     queue.push(initialState.modals.eventDiscount);
  // }

  return queues;
};

/**
 * Set timeout before dhow modal on site
 * @param {string} key Modal key
 * @param {number} time Time on seconds
 * @param {function} dispatch Dispatch function
 * @returns {number}
 */
const setModalShowTimeout = (key, time, dispatch) => {
  return setTimeout(() => {
    dispatch(setAfterTimeout(key));
  }, time * 1000);
};

/**
 * Remove modal from queue by key
 *
 * @param {Object} state Storage object
 * @param key
 * @return {[]}
 */
function removeModalFromQueue(state, key) {
  for (let i = 0; i < state.queue.length; i++) {
    if (state.queue[i].key === key) {
      state.queue.splice(i, 1);
    }
  }

  return state.queue;
}

function resortModalsQueue(state, key) {
  for (let i = 0; i < state.queue.length; i++) {
    if (state.queue[i].key === key) {
      state.queue.unshift(state.queue[i]);
      state.queue.splice(i + 1, 1);
      break;
    }
  }

  return state.queue;
}

/**
 * Set next queue to show
 * @param {Object} state State object
 * @param {function} dispatch Dispatch function
 * @param {boolean} hasPrev Has prev modal in queue
 * @param {null|number} extraTimeout Extra timeout in seconds
 * @return {*}
 */
const nextQueue = (state, dispatch, hasPrev = false, extraTimeout = null) => {
  Object.keys(state.modals).forEach((key) => {
    if (state.modals[key]?.show) {
      state.modals[key].show = false;
    }
  });

  state.hasActiveModal = false;
  state.activeModalKey = null;
  state.timeout = null;

  if (state.queue.length > 0) {
    const currentQueue = state.queue[0];
    state.activeModalKey = currentQueue.key;

    if (
      !state.modals[state.activeModalKey].timeout &&
      !(hasPrev && state.modals[state.activeModalKey].timeoutAfterPrev)
    ) {
      state.modals[state.activeModalKey].show = true;
    } else {
      state.timeout = setModalShowTimeout(
        state.activeModalKey,
        hasPrev
          ? state.modals[state.activeModalKey].timeoutAfterPrev +
              (extraTimeout ?? 0)
          : state.modals[state.activeModalKey].timeout,
        dispatch
      );
    }
  }

  return state;
};

export const { init, next, setAfterTimeout, showManual, addFirstModal } =
  modalQueueSlice.actions;

export default modalQueueSlice.reducer;
