import { createSlice } from "@reduxjs/toolkit";

import { GENDER_MALE } from "shared/constants";
import { OPTION_DISABLED } from "shared/constants/options";

const initialState = {
  gender: {
    value: GENDER_MALE,
    error: false,
    message: "",
  },
  birthday: {
    value: "",
    error: false,
    message: "",
  },
  password: {
    value: "",
    error: false,
    message: "",
  },
  error: {
    error: false,
    message: "",
  },
  month: {
    value: "",
    error: false,
    message: "",
  },
  day: {
    value: "",
    error: false,
    message: "",
  },
  year: {
    value: "",
    error: false,
    message: "",
  },
  terms: {
    value: OPTION_DISABLED,
    error: false,
    message: "",
  },
  valid: true,
  send: false,
};

const profileSimpleSlice = createSlice({
  name: "profile/simple",
  initialState,
  reducers: {},
});

export default profileSimpleSlice.reducer;
