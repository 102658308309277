import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: {},
};

const chatFilesSlice = createSlice({
  name: "chat/files",
  initialState,
  reducers: {
    set: (state, { payload }) => {
      state.items[payload.identity] = {
        files: payload.files,
      };
    },
    addItem: (state, { payload }) => {
      if (!state.items[payload.identity]) {
        state.items[payload.identity] = {
          files: [],
        };
      }

      let alreadyExist = false;
      let iteration = 0;

      for (const file of state.items[payload.identity].files) {
        if (file.uid === payload.file.uid) {
          state.items[payload.identity].files[iteration] = payload.file;
          alreadyExist = true;
          break;
        }

        iteration++;
      }

      if (!alreadyExist) {
        state.items[payload.identity].files.push(payload.file);
      }
    },
    deleteItem: (state, { payload }) => {
      if (state.items[payload.identity]?.files) {
        let iteration = 0;
        for (const file of state.items[payload.identity].files) {
          if (file.uid === payload.file.uid) {
            state.items[payload.identity].files.splice(iteration, 1);
            break;
          }

          iteration++;
        }
      }
    },
    deleteChannelItems: (state, { payload }) => {
      if (state.items[payload.identity]?.files) {
        state.items[payload.identity].files = [];
      }
    },
  },
});

export const { set, addItem, deleteItem, deleteChannelItems } =
  chatFilesSlice.actions;

export default chatFilesSlice.reducer;
