import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notEnoughError: false,
  destUserUid: null,
};

const purchaseSlice = createSlice({
  name: "profile/purchase",
  initialState,
  reducers: {
    toggleNotEnoughError: (state, { payload }) => {
      state.notEnoughError = payload?.show;
      state.destUserUid = payload?.show && payload?.uid ? +payload?.uid : null;
    },
  },
});

export const { toggleNotEnoughError } = purchaseSlice.actions;

export default purchaseSlice.reducer;
