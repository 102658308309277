import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  categories: [],
  uploaded: false,
  send: false,
  open: false,
  error: {
    error: false,
    errorCode: null,
    message: "",
  },
};

const giftsSlice = createSlice({
  name: "gifts",
  initialState,
  reducers: {
    setGifts: (state, { payload }) => {
      const notEmptyCategories = payload.categories.filter((category) =>
        payload.gifts.some((gift) => gift.category_uid === category.uid)
      );
      return {
        ...state,
        items: state.items.concat(payload.gifts),
        categories: state.items.concat(notEmptyCategories),
        uploaded: true,
        send: false,
        open: true,
      };
    },
    clearGifts: () => {
      return {
        ...initialState,
      };
    },
    toggleSendGifts: (state, { payload }) => {
      state.send = payload;
    },
    removeGift: (state, { payload }) => {
      const gift = state.items.find((item) => item.uid === payload);

      return {
        ...state,
        categories: state.categories.filter(
          (item) => item.uid !== gift.category_uid
        ),
        items: state.items.filter((item) => item.uid !== payload),
      };
    },
    setOpenGifts: (state, { payload }) => {
      state.open = payload;
    },
  },
});
export const {
  setGifts,
  removeGift,
  clearGifts,
  toggleSendGifts,
  setOpenGifts,
} = giftsSlice.actions;
export default giftsSlice.reducer;
