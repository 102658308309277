import { createSlice } from "@reduxjs/toolkit";

import {
  NOT_LIKED_STATUS,
  USER_NOT_VERIFIED,
  WINKED_STATUS,
} from "shared/constants";
import {
  CHAT_MESSAGE_TYPE_TEXT,
  GIFT_STATUS_OPENED,
  MESSAGE_MEDIA_STATUS_BROKEN,
  MESSAGE_MEDIA_STATUS_SEND,
} from "shared/constants/chat";

import {
  addMessageItemToStorage,
  deleteMessageItemFromStorage,
  deleteMessageItemsFromStorage,
  getMessageItemsFromStorage,
} from "shared/helpers/chat";
import { sortObject } from "shared/helpers/view";

const initialState = {
  items: {},
};

const chatMessagesSlice = createSlice({
  name: "chat/messages",
  initialState,
  reducers: {
    addItem: (state, { payload }) => {
      if (state.items[payload.identity]?.uploaded) {
        if (
          payload.key &&
          state.items[payload.identity].messages[payload.key]
        ) {
          delete state.items[payload.identity].messages[payload.key];
        }

        addItemsToState(
          state.items[payload.identity],
          [payload.item],
          payload.identity
        );
      }
    },
    deleteItem: (state, { payload }) => {
      if (state.items[payload.identity]?.messages[payload.sid]) {
        delete state.items[payload.identity]?.messages[payload.sid];
        deleteMessageItemFromStorage(payload.identity, payload.sid);

        addItemsToState(state.items[payload.identity], [], payload.identity);
      }
    },
    addItems: (state, { payload }) => {
      if (!state.items[payload.identity]) {
        state.items[payload.identity] = getTempItemObject();
      }

      state.items[payload.identity].token =
        payload.token ?? state.items[payload.identity].token;
      state.items[payload.identity].uploaded = true;
      state.items[payload.identity].uploadNew = false;
      state.items[payload.identity].send = false;

      addItemsToState(
        state.items[payload.identity],
        payload.items,
        payload.identity
      );
    },
    toggleSend: (state, { payload }) => {
      if (!state.items[payload.identity]) {
        state.items[payload.identity] = getTempItemObject();
      }

      if (state.items[payload.identity].send !== payload.status) {
        state.items[payload.identity].send = payload.status;
      }
    },
    updateUserInfo: (state, { payload }) => {
      if (!state.items[payload.identity]) {
        state.items[payload.identity] = getTempItemObject();
      }

      if (state.items[payload.identity].is_winked !== payload.is_winked) {
        state.items[payload.identity].is_winked = payload.is_winked;
      }
      if (state.items[payload.identity].is_liked !== payload.is_liked) {
        state.items[payload.identity].is_liked = payload.is_liked;
      }
      if (state.items[payload.identity].verified !== payload.verified) {
        state.items[payload.identity].verified = payload.verified;
      }
      if (
        state.items[payload.identity].received_free_msg !==
        payload.received_free_msg
      ) {
        state.items[payload.identity].received_free_msg =
          payload.received_free_msg;
      }
    },
    setLike: (state, { payload }) => {
      if (!state.items[payload.identity]) {
        state.items[payload.identity] = getTempItemObject();
      }

      if (state.items[payload.identity].is_liked !== payload.is_liked) {
        state.items[payload.identity].is_liked = payload.is_liked;
      }
    },
    setWinked: (state, { payload }) => {
      if (!state.items[payload.identity]) {
        state.items[payload.identity] = getTempItemObject();
      }

      if (state.items[payload.identity].is_winked !== payload.is_winked) {
        state.items[payload.identity].is_winked = payload.is_winked;
      }
    },
    deleteChannelMessages: (state, { payload }) => {
      if (state.items[payload]) {
        delete state.items[payload];
        deleteMessageItemsFromStorage(payload);
      }
    },
    clearItems: (state) => {
      state.items = {};
    },
    setUploadNewStatus: (state) => {
      if (Object.keys(state.items).length > 0) {
        Object.keys(state.items).forEach((key) => {
          if (
            state.items[key]?.uploadNew === false &&
            state.items[key]?.uploaded
          ) {
            state.items[key].uploadNew = true;
          }
        });
      }
    },
    toggleUploadNewStatus: (state, { payload }) => {
      if (!state.items[payload.identity]) {
        state.items[payload.identity] = getTempItemObject();
      }

      if (state.items[payload.identity].uploadNew !== payload.status) {
        state.items[payload.identity].uploadNew = payload.status;
      }
    },
    stopLoadMore: (state, { payload }) => {
      if (!state.items[payload.identity]) {
        state.items[payload.identity] = getTempItemObject();
      }

      state.items[payload.identity].stopLoadMore = true;
    },
    addError: (state, { payload }) => {
      if (!state.items[payload.identity]) {
        state.items[payload.identity] = getTempItemObject();
      }

      if (state.items[payload.identity].messages[payload.sid]) {
        state.items[payload.identity].messages[payload.sid].error.show =
          payload.status;
        state.items[payload.identity].messages[payload.sid].error.message =
          payload.message;
        state.items[payload.identity].messages[payload.sid].error.reTry =
          payload.reTry;
      }
    },
    updateMedia: (state, { payload }) => {
      if (
        state.items[payload.identity]?.messages[payload.sid]?.media[
          payload.media.sid
        ]
      ) {
        state.items[payload.identity].messages[payload.sid].media[
          payload.media.sid
        ] = payload.media;

        updateRetryStatus(
          state,
          payload.identity,
          state.items[payload.identity].messages[payload.sid]
        );
      }
    },
    deleteMedia: (state, { payload }) => {
      if (
        state.items[payload.identity]?.messages[payload.sid]?.media[
          payload.mediaSid
        ]
      ) {
        delete state.items[payload.identity].messages[payload.sid].media[
          payload.mediaSid
        ];

        if (
          Object.keys(state.items[payload.identity].messages[payload.sid].media)
            .length === 0
        ) {
          state.items[payload.identity].messages[payload.sid].type =
            CHAT_MESSAGE_TYPE_TEXT;
        }

        updateRetryStatus(
          state,
          payload.identity,
          state.items[payload.identity].messages[payload.sid]
        );
      }
    },
    setOpenGift: (state, { payload }) => {
      if (state.items[payload.identity]?.messages[payload.sid]) {
        state.items[payload.identity].messages[payload.sid].gift = {
          ...state.items[payload.identity].messages[payload.sid].gift,
          status: GIFT_STATUS_OPENED,
        };
      }
    },
    setShouldOpenGift: (state, { payload }) => {
      if (state.items[payload.identity]?.messages[payload.sid]) {
        state.items[payload.identity].messages[payload.sid].gift = {
          ...state.items[payload.identity].messages[payload.sid].gift,
          shouldOpen: payload.status,
        };
      }
    },
  },
});

/**
 * Get temporary message object
 * @return {{is_winked: number, uploaded: boolean, messages: {}, send: boolean}}
 */
const getTempItemObject = () => {
  return {
    uploaded: false,
    uploadNew: false,
    stopLoadMore: true,
    send: false,
    token: null,
    lastIndex: null,
    received_free_msg: true,
    is_winked: WINKED_STATUS,
    is_liked: NOT_LIKED_STATUS,
    verified: USER_NOT_VERIFIED,
    messages: {},
  };
};

/**
 * Prepare messages for feed
 * @param {Object} state Message object
 * @param {Array} items List of new messages
 * @param {string} identity Channel identity
 */
const addItemsToState = (state, items, identity) => {
  let messages = JSON.parse(JSON.stringify(state?.messages ?? {}));
  const storageMessages = getMessageItemsFromStorage(identity);

  let sortMessages = {};

  if (items.length > 0) {
    for (const item of items) {
      messages[item.sid] = {
        ...item,
        ...{
          error: {
            show: false,
            message: null,
            reTry: false,
          },
        },
      };

      if (state.token === null || +item.index < +state.token) {
        state.token = +item.index;
      }

      if (state.token === null || +item.index < +state.token) {
        state.token = +item.index;
      }

      if (state.lastIndex === null || +item.index > +state.lastIndex) {
        state.lastIndex = +item.index;
      }
    }
  }

  state.stopLoadMore = state.token <= 1;

  /**
   * Add message from local storage to message list
   */
  if (storageMessages && Object.keys(storageMessages).length > 0) {
    if (state.stopLoadMore && Object.keys(messages).length === 0) {
      /**
       * If all messages of chat already uploaded ot list of message empty
       * wee add all local storage messages to chat messages list
       */
      messages = { ...messages, ...storageMessages };
      sortMessages = sortObject(messages, "created", false);
    } else {
      /**
       * Add local storage messages to chat messages list according to time when they are created
       */
      sortMessages = sortObject(messages, "created", false);

      const sortKeys = Object.keys(sortMessages);
      const oldestMessage = sortMessages[sortKeys[0]];
      let needSort = false;

      Object.keys(storageMessages).forEach((key) => {
        const message = storageMessages[key];
        if (+oldestMessage.created > +message.created) {
          return;
        }

        if (!sortMessages[message.sid]) {
          needSort = true;
        }
        sortMessages[message.sid] = message;
      });

      if (needSort) {
        sortMessages = sortObject(sortMessages, "created", false);
      }
    }
  } else {
    sortMessages = sortObject(messages, "created", false);
  }

  state.messages = sortMessages;
};

/**
 * Update message retry status
 * @param {Object} state Message state
 * @param {string} identity Channel identity
 * @param {Object} message Message object
 */
const updateRetryStatus = (state, identity, message) => {
  if (!message?.error?.show) {
    return;
  }

  const hasBody = message?.body?.length > 0;
  let hasBrokenMedia = false;

  if (message.media && Object.keys(message.media).length > 0) {
    Object.keys(message.media).forEach((key) => {
      if (
        [MESSAGE_MEDIA_STATUS_BROKEN, MESSAGE_MEDIA_STATUS_SEND].includes(
          message.media[key].status
        )
      ) {
        hasBrokenMedia = true;
      }
    });
  }

  if (hasBrokenMedia) {
    return;
  }

  if (Object.keys(message.media).length > 0 || hasBody) {
    state.items[identity].messages[message.sid].error.reTry = true;
  }

  addMessageItemToStorage(identity, message);
};

export const {
  addItem,
  deleteItem,
  addItems,
  toggleSend,
  toggleUploadNewStatus,
  updateUserInfo,
  deleteChannelMessages,
  clearItems,
  setUploadNewStatus,
  setLike,
  setWinked,
  stopLoadMore,
  addError,
  updateMedia,
  deleteMedia,
  setOpenGift,
  setShouldOpenGift,
} = chatMessagesSlice.actions;

export default chatMessagesSlice.reducer;
