import { createSlice } from "@reduxjs/toolkit";

import {
  FREE_MSG_DISABLED,
  GALLERY_SHOW_ENABLED,
  ICEBREAKER_USED,
  PURCHASE_TYPE_FREE,
  TYPE_PHOTO,
  USER_VERIFIED,
} from "shared/constants";
import { EVENT_CONFIRM_CHAT } from "shared/constants/cookie";
import { OPTION_ENABLED } from "shared/constants/options";

import { existCookie } from "shared/helpers/cookie";
import { getTemporaryProfileMedia } from "shared/helpers/media";
import { escapeText } from "shared/helpers/view";

import { initialState as initialCriteriaState } from "shared/redux/slices/criteria";

const initialState = {
  uid: "",
  first_name: "",
  birthday: "",
  billing_type: null,
  discounts: {
    reactivation: { enabled: false, credits: 0 },
    halloween: {
      enabled: false,
    },
    blackFriday: {
      enabled: false,
    },
    isFetched: false,
  },
  gallery_show: GALLERY_SHOW_ENABLED,
  email: "",
  age: "",
  balance: 0,
  avatar: null,
  residence_country: "",
  registration_country: "",
  is_completed: true,
  residence_city: "",
  height: "",
  weight: "",
  body_type: "",
  marital_status: "",
  children: "",
  education_level: "",
  religion: "",
  smoke: "",
  drink: "",
  occupation: "",
  bio: "",
  more_children: "",
  images: [],
  count_image: 0,
  free_msg_zero: 0,
  count_video: 0,
  criteria: initialCriteriaState,
  preferences: {},
  pres_icebreak: ICEBREAKER_USED,
  in_app_sound: OPTION_ENABLED,
  push_notify_message: OPTION_ENABLED,
  push_notify_view: OPTION_ENABLED,
  push_notify_like: OPTION_ENABLED,
  push_notify_favorite: OPTION_ENABLED,
  push_notify_marketing: OPTION_ENABLED,
  email_notify_message: OPTION_ENABLED,
  email_notify_like: OPTION_ENABLED,
  email_notify_view: OPTION_ENABLED,
  email_notify_favorite: OPTION_ENABLED,
  email_notify_marketing: OPTION_ENABLED,
  email_verification_status: "",
  notify_chat: OPTION_ENABLED,
  notify_mail: OPTION_ENABLED,
  notify_wink: OPTION_ENABLED,
  notify_activity: OPTION_ENABLED,
  verified: USER_VERIFIED,
  profile_view_time: null,
  pMaintenance: false,
  send: false,
  uploaded: false,
  agoraData: null,
  zeroStateValue: 0,
  showConfirmation: false,
  free_msg: FREE_MSG_DISABLED,
  error: {
    error: false,
    message: "",
  },
  disc: {
    verPromo: {
      enabled: false,
      amount: 0,
    },
    webPushSub: {
      enabled: false,
      amount: 0,
    },
    hel: {
      enabled: false,
    },
    reg: {
      enabled: false,
      amount: 0,
    },
    autoLogin: {
      enabled: false,
      amount: 0,
      showSuccess: false,
    },
    mail: {
      enabled: false,
      amount: 0,
      showSuccess: false,
      showFirst: false,
    },
    oneTime: {
      enabled: false,
      amount: 0,
      showConfirm: false,
      showConfirmFirst: true,
    },
    halfPrice: {
      enabled: false,
    },
    remarketing: {
      enabled: false,
    },
  },
  discSubSuccess: false,
  firebaseLoaded: false,
  firebaseInited: false,
  firebaseRequestSend: false,
  hellDiscountSleep: false,
  split: [],
  purchase_type: PURCHASE_TYPE_FREE,
  stream_available: false,
  confirm_chat_data: null,
  verify_steps: 0,
  email_confirm_chat: false,
  app_version: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, { payload }) => {
      let images = payload.images.map((image) => {
        return {
          ...image,
          ...{
            uploaded: true,
            send: false,
            error: {
              status: false,
              message: null,
            },
          },
        };
      });

      if (state.images?.length > 0) {
        const uploadImages = state.images.filter(
          (image) => (!image.uploaded && image.send) || image?.error?.status
        );
        images = uploadImages.concat(images);
      }

      return {
        ...state,
        ...payload,
        ...{
          first_name: escapeText(payload.first_name),
          bio: escapeText(payload.bio),
          occupation: escapeText(payload.occupation),
          firebaseInited: state.firebaseInited,
          firebaseRequestSend: state.firebaseRequestSend,
          firebaseLoaded: state.firebaseLoaded,
          discSubSuccess: state.discSubSuccess,
          criteria: state.criteria,
          uploaded: true,
          discounts: state.discounts,
          avatar: { ...payload.avatar, ...{ uploaded: true, send: false } },
          images,
        },
      };
    },
    updateVerifiedProfile: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    updateBalance: (state, { payload }) => {
      state.balance = payload ?? 0;
    },
    toggleSend: (state, { payload }) => {
      state.send = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload ?? {
        error: false,
        message: "",
      };
    },
    updateSplit: (state, { payload }) => {
      state.split = payload;
    },
    updatePreferencesAge: (state, { payload }) => {
      state.preferences.age_from = payload.age_from;
      state.preferences.age_to = payload.age_to;
    },
    updateResidenceCity: (state, { payload }) => {
      state.residence_city = payload;
    },
    updateResidenceCountry: (state, { payload }) => {
      state.residence_country = payload;
      state.residence_city = "";
    },
    setEmail: (state, { payload }) => {
      state.email = payload;
    },
    setAvatar: (state, { payload }) => {
      state.avatar = { ...payload, ...{ uploaded: true, send: false } };
    },
    setSteps: (state, { payload }) => {
      state.verify_steps = payload;
    },
    updateDiscount: (state, { payload }) => {
      if (state.disc[payload.key]) {
        if (payload.attributes) {
          Object.keys(payload.attributes).forEach((key) => {
            if (typeof state.disc[payload.key][key] !== "undefined") {
              state.disc[payload.key][key] = payload.attributes[key];
            }
          });
        }
      }
    },
    switchSoundApp: (state, { payload }) => {
      state.in_app_sound = payload;
    },
    switchNotificationsApp: (state, { payload }) => {
      state[payload.attr] = payload.value;
    },
    setAgoraData: (state, { payload }) => {
      state.agoraData = payload;
    },
    addMedia: (state, { payload }) => {
      state.images = [payload].concat(state.images);
    },
    replaceMedia: (state, { payload }) => {
      for (const [index, item] of state.images.entries()) {
        if (item.uid === payload.uid) {
          state.images[index] = payload.data;
          break;
        }
      }
    },
    updateCoRegistrationData: (state) => {
      state.disc.remarketing.enabled = false;
    },
    updateMedia: (state, { payload }) => {
      for (const [index, item] of state.images.entries()) {
        if (item.uid === payload.uid) {
          state.images[index] = { ...state.images[index], ...payload.data };
          break;
        }
      }
    },
    toggleMediaSend: (state, { payload }) => {
      for (const [index, item] of state.images.entries()) {
        if (item.uid === payload.uid) {
          state.images[index].send = payload.send;
          break;
        }
      }
    },
    toggleAvatarSend: (state, { payload }) => {
      state.avatar.send = payload;
    },
    removeMedia: (state, { payload }) => {
      if (payload.uid === state.avatar?.uid) {
        state.avatar = getTemporaryProfileMedia({ uid: 0, type: TYPE_PHOTO });
      } else {
        state.images = state.images.filter(
          (image) => image?.uid !== payload.uid
        );
      }
    },
    setConfirmChatData: (state, { payload }) => {
      if (!state.email_confirm_chat || existCookie(EVENT_CONFIRM_CHAT)) {
        return state;
      }

      if (payload && payload?.uid !== state.confirm_chat_data?.uid) {
        state.confirm_chat_data = payload;
      } else if (!payload && state.confirm_chat_data?.uid) {
        state.confirm_chat_data = null;
      }
    },
    setProfileFreeMsgCount: (state, { payload }) => {
      state.free_msg_zero = payload;
    },
    fbInited: (state, { payload }) => {
      state.firebaseInited = !!payload;
    },
    fbLoaded: (state, { payload }) => {
      state.firebaseLoaded = !!payload;
    },
    fbSend: (state, { payload }) => {
      state.firebaseRequestSend = !!payload;
    },
    setDiscounts: (state, { payload }) => {
      state.discounts = { ...payload, isFetched: true };
    },
    setDiscountNotFound: (state) => {
      state.discounts.isFetched = true;
    },
    toggleReactivationDiscount: (state, { payload }) => {
      if (state.discounts.reactivation) {
        state.discounts.reactivation.enabled = payload;
      }
    },
    toggleHalloweenDiscount: (state, { payload }) => {
      if (state.discounts.halloween) {
        state.discounts.halloween.enabled = payload;
      }
    },
    toggleBlackFridayDiscount: (state, { payload }) => {
      if (state.discounts.blackFriday) {
        state.discounts.blackFriday.enabled = payload;
      }
    },
  },
});

export const {
  addMedia,
  fbInited,
  fbLoaded,
  fbSend,
  removeMedia,
  replaceMedia,
  setAgoraData,
  setAvatar,
  setConfirmChatData,
  setDiscountNotFound,
  setDiscounts,
  setEmail,
  setError,
  setProfile,
  updateVerifiedProfile,
  setProfileFreeMsgCount,
  setSteps,
  switchNotificationsApp,
  switchSoundApp,
  toggleAvatarSend,
  toggleHalloweenDiscount,
  toggleBlackFridayDiscount,
  toggleMediaSend,
  toggleReactivationDiscount,
  toggleSend,
  updateBalance,
  updateCoRegistrationData,
  updateDiscount,
  updateMedia,
  updatePreferencesAge,
  updateResidenceCity,
  updateResidenceCountry,
  updateSplit,
} = profileSlice.actions;

export default profileSlice.reducer;
