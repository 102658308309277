export const MAIL_ACTIVE_TAB_INBOX = "inbox";
export const MAIL_ACTIVE_TAB_OUTBOX = "outbox";
export const MAIL_ACTIVE_TAB_TRASH = "trash";
export const MAIL_ACTIVE_TAB_DRAFT = "draft";

export const MAIL_ANSWERED_TYPE_ALL = 0;
export const MAIL_ANSWERED_TYPE_NEW = 1;
export const MAIL_ANSWERED_TYPE_UN_ANSW = 2;

export const MAIL_FILTER_DISABLED = 0;
export const MAIL_FILTER_ENABLED = 1;

export const MAIL_QUERY_LIMIT = 20;

export const MAIL_DELETE_PERMANENT = 1;
export const MAIL_DELETE_NOT_PERMANENT = 0;

export const MAIL_IS_UNREAD = 1;
export const MAIL_IS_READ = 0;

export const MAIL_INPUT_TYPE_DIALOG = 1;
export const MAIL_INPUT_TYPE_USER = 2;

export const tabsEvents = {
  [MAIL_ACTIVE_TAB_INBOX]: "cl_mail_inbox",
  [MAIL_ACTIVE_TAB_OUTBOX]: "cl_mail_outbox",
  [MAIL_ACTIVE_TAB_TRASH]: "cl_mail_trash",
  [MAIL_ACTIVE_TAB_DRAFT]: "cl_mail_drafts",
};

export const mailTabs = [
  MAIL_ACTIVE_TAB_INBOX,
  MAIL_ACTIVE_TAB_OUTBOX,
  MAIL_ACTIVE_TAB_TRASH,
  MAIL_ACTIVE_TAB_DRAFT,
];
