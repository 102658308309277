import axios from "axios";

import { updateCounters } from "shared/redux/slices/notify/counters";
import { updateBalance } from "shared/redux/slices/profile";

import { isAuthorized } from "./auth";

import store from "@storeEnv/appStore";

if (import.meta.env.VITE_APP_API_HOST !== "") {
  axios.defaults.withCredentials = true;
}

axios.interceptors.response.use(
  (response) => {
    if (response?.data?.success && response?.data?.notify) {
      store.dispatch(updateCounters(response.data.notify));

      if (response?.data?.balance !== undefined) {
        store.dispatch(updateBalance(+response.data.balance));
      }
    }

    return response;
  },
  (error) => {
    if (isAuthorized(error?.response)) {
      if (error?.data?.notify) {
        store.dispatch(updateCounters(error.data.notify));
      }

      if (error?.data?.balance !== undefined) {
        store.dispatch(updateBalance(+error.data.balance));
      }
    }

    return Promise.reject(error);
  }
);

/**
 * Seng get request to url
 */
export function get<Type>(url: string, params: any | null = null) {
  return axios.get<Type>(url, params);
}

/**
 * Seng post request to url
 */
export const post = (
  url: string,
  attributes: any = null,
  headers: any = null
) => axios.post(url, attributes, headers);

/**
 * Seng put request to url
 */
export const put = (url: string, attributes: any = null, headers: any = null) =>
  axios.put(url, attributes, headers);
