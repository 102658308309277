import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  send: false,
  upload: false,
  token: null,
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setContacts: (state, { payload }) => {
      return {
        ...state,
        items: state.items.concat(payload),
        uploaded: true,
        send: false,
      };
    },
    clearContacts: () => {
      return {
        ...initialState,
      };
    },
    toggleSendContacts: (state, { payload }) => {
      state.send = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
  },
});

export const { setContacts, clearContacts, toggleSendContacts, setToken } =
  contactsSlice.actions;
export default contactsSlice.reducer;
