import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countries: {},
  send: false,
  upload: false,
};

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    toggleSend: (state, { payload }) => {
      state.send = payload;
    },
    set: (state, { payload }) => {
      state.countries = payload ?? {};
      state.send = false;
      state.upload = true;
    },
    clear: (state) => {
      state.countries = {};
      state.send = false;
      state.upload = false;
    },
  },
});

export const { toggleSend, set, clear } = countrySlice.actions;

export default countrySlice.reducer;
