import { createSlice } from "@reduxjs/toolkit";

import { IS_LIKED } from "shared/constants/news";

const initialState = {
  items: [],
  token: "",
  uploaded: false,
  send: false,
  loadMore: true,
  error: {
    error: false,
    errorCode: null,
    message: "",
  },
};

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {
    setFavorites: (state, { payload }) => {
      return {
        ...state,
        items: state.items.concat(payload.items),
        token: payload.token,
        uploaded: true,
        send: false,
      };
    },
    clearFavorites: () => {
      return {
        ...initialState,
      };
    },
    toggleSendFavorites: (state, { payload }) => {
      state.send = payload;
    },

    toggleFavorite: (state, { payload }) => {
      for (const [index, item] of state.items.entries()) {
        if (item.uid === payload.uid) {
          if (item.is_favored !== payload.favorite) {
            state.items[index].is_favored = payload.favorite;
            break;
          }
        }
      }

      return state;
    },
    setLikedFavorite: (state, { payload }) => {
      state.items = state.items.map((item) =>
        item.uid === payload.uid ? { ...item, is_liked: IS_LIKED } : item
      );
    },
  },
});
export const {
  setFavorites,
  toggleSendFavorites,
  toggleFavorite,
  setLikedFavorite,
  clearFavorites,
} = favoritesSlice.actions;
export default favoritesSlice.reducer;
