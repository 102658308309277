import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  city: [],
  country_code: "",
  send: false,
  upload: false,
};

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    toggleSend: (state, { payload }) => {
      state.send = payload;
    },
    set: (state, { payload }) => {
      state.city = payload?.city ?? [];
      state.country_code = payload?.country ?? "";
      state.send = false;
      state.upload = true;
    },
    clear: (state) => {
      state.city = [];
    },
  },
});

export const { toggleSend, set, clear } = citySlice.actions;

export default citySlice.reducer;
