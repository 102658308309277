import { createSlice } from "@reduxjs/toolkit";

import { IS_ALL_POSTS } from "shared/constants/news";

import { countReactions } from "shared/helpers/reactions.js";

const initialState = {
  items: [],
  token: "",
  isFollowing: IS_ALL_POSTS,
  total: 0,
  error: {
    error: false,
    message: "",
  },
  haveToCheck: false,
  hasNew: false,
  checked: false,
  send: false,
  uploaded: false,
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    set: (state, { payload }) => {
      const addReactionsCount = (items) => {
        return items.map((item) => ({
          ...item,
          reactions_count: countReactions(item),
        }));
      };

      return {
        ...state,
        items: payload.isNew
          ? [...addReactionsCount(payload.items), ...state.items]
          : [...state.items, ...addReactionsCount(payload.items)],
        token: payload.token,
        send: false,
        uploaded: true,
        ...(Object.prototype.hasOwnProperty.call(payload, "isFollowing") && {
          isFollowing: payload.isFollowing,
        }),
      };
    },
    toggleSend: (state, { payload }) => {
      state.send = payload;
    },
    setFollow: (state, { payload }) => {
      state.items = state.items.map((item) =>
        item.user_uid === payload.uid
          ? {
              ...item,
              is_favored: payload.favorite,
            }
          : item
      );
    },
    setReport: (state, { payload }) => {
      state.items = state.items.map((item) =>
        item.user_uid === payload ? { ...item, has_report: true } : item
      );
    },
    setReaction: (state, { payload }) => {
      state.items = state.items.map((item) =>
        item.uid === payload.id
          ? {
              ...item,
              [payload.reactionField]: payload.status,
              reactions_count: payload.reactions_count,
            }
          : item
      );
    },
    removeBlocked: (state, { payload }) => {
      state.items = state.items.filter((item) => item.user_uid !== payload);
    },
    clearNews: () => {
      return initialState;
    },
    setHasNew: (state, { payload }) => {
      state.hasNew = payload.new_post_exist;
      state.haveToCheck = false;
      state.checked = Object.prototype.hasOwnProperty.call(payload, "checked")
        ? payload.checked
        : true;
    },
    setUncheck: (state) => {
      state.checked = false;
    },
    setHaveToCheck: (state, { payload }) => {
      state.haveToCheck = payload;
    },
    clearCheckValue: (state) => {
      state.checked = false;
    },
  },
});

export const {
  set,
  toggleSend,
  setFollow,
  setReport,
  setReaction,
  removeBlocked,
  clearNews,
  setHasNew,
  setUncheck,
  setHaveToCheck,
  clearCheckValue,
} = newsSlice.actions;

export default newsSlice.reducer;
