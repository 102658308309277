import { createSlice } from "@reduxjs/toolkit";

import { LIKED_STATUS } from "shared/constants";

const initialState = {
  data: {
    uid: null,
    first_name: "",
    // last_name: "",
    age: null,
    avatar: {},
    is_banned: false,
    is_online: null,
    is_newbie: null,
    is_favored: null,
    is_liked: null,
    is_winked: null,
    is_streamer: false,
    verified: null,
    location: "",
    count_image: null,
    count_video: null,
    images: [],
    height: null,
    weight: null,
    body_type: "",
    marital_status: "",
    children: "",
    more_children: "",
    education_level: "",
    religion: "",
    smoke: "",
    drink: "",
    occupation: "",
    bio: "",
    has_report: false,
    m_profile: null,
    ftype: null,
    chatRequired: false,
    isChatShow: false,
  },
  send: false,
  uploaded: false,
  error: {
    show: false,
    errorCode: null,
    message: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    set: (state, { payload }) => {
      return {
        data: payload,
        send: false,
        uploaded: true,
        error: {
          show: false,
          errorCode: null,
          message: "",
        },
      };
    },
    setUserMedia: (state, { payload }) => {
      if (+state.data.uid !== +payload?.uid) {
        return;
      }

      state.data.images = payload.images;
    },
    toggleFavorite: (state, { payload }) => {
      if (state.data.uid !== payload.uid) {
        return;
      }

      if (state.data.is_favored !== payload.favorite) {
        state.data.is_favored = payload.favorite;
      }
    },
    preSetUser: (state, { payload }) => {
      state.data = { ...state.data, ...payload };
      state.uploaded = true;
    },
    toggleSend: (state, { payload }) => {
      state.send = payload;
    },
    setLike: (state, { payload }) => {
      if (state.data.uid === payload && state.data.is_liked !== LIKED_STATUS) {
        state.data.is_liked = LIKED_STATUS;
      }
    },
    updateMedia: (state, { payload }) => {
      for (const [index, item] of state.data.images.entries()) {
        if (item.uid === payload.uid) {
          state.data.images[index] = payload;
          break;
        }
      }
    },
    clearUser: () => {
      return initialState;
    },
    toggleReport: (state, { payload }) => {
      if (
        +state.data?.uid === +payload.uid &&
        state.data?.has_report !== payload.has_report
      ) {
        state.data.has_report = payload.has_report;
      }
    },
    setError: (state, { payload }) => {
      if (!payload) {
        state.error.show = false;
        state.error.message = null;
      } else {
        state.error.show = true;
        state.error.message = payload;
      }
    },
    setUnBlock: (state, { payload }) => {
      if (+state.data?.uid === +payload.uid && state.data?.is_banned) {
        state.data.is_banned = false;
      }
    },
    setIsChatShow: (state, { payload }) => {
      state.data.isChatShow = payload;
    },
  },
});

export const {
  setUserMedia,
  toggleFavorite,
  preSetUser,
  toggleSend,
  set,
  setLike,
  updateMedia,
  toggleReport,
  clearUser,
  setError,
  setIsChatShow,
  setUnBlock,
} = userSlice.actions;

export default userSlice.reducer;
