import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  packageList: [],
  paymentMethodList: null,
  selectedPackage: null,
  defaultPackage: null,
  bestPackageId: null,
  oneTimePackageId: null,
  requestValidationToken: null,
  paymentType: "",
  packageType: "",
  activateSubscriptions: 0,
  error: false,
  errorList: {},
  valid: true,
  send: false,
  oneClick: null,
  paymentProcessStatus: null,
  declineReason: null,
  uploaded: false,
  oneClickShowed: false,
  isGPayAvailable: false,
  isApplePayAvailable: false,
  oneClickInvoiceId: null,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    toggleSend: (state, { payload }) => {
      state.send = payload;
    },
    set: (state, { payload }) => {
      state.valid = true;
      state.send = false;
      state.error = false;
      state.errorList = {};
      state.packageList = payload.packageList ?? [];
      state.selectedPackage = payload.selectedPackage ?? null;
      state.defaultPackage = payload.defaultPackage ?? null;
      state.bestPackageId = payload.bestPackageId ?? null;
      state.packageType = payload.packageType ?? "";
      state.requestValidationToken = payload.requestValidationToken ?? null;
      state.paymentMethodList = payload.paymentMethodList ?? [];
      state.oneTimePackageId = payload.oneTimePackageId ?? null;
      state.paymentProcessStatus = null;
      state.oneClick = payload.oneClick;
      state.uploaded = true;
      state.oneClickInvoiceId = null;
    },
    selectPackage: (state, { payload }) => {
      state.selectedPackage = payload;
    },
    setPaymentStatus: (state, { payload }) => {
      state.paymentProcessStatus = payload.status;
      if (payload.reason) {
        state.declineReason = payload.reason;
      }
    },
    setGPayAvailableStatus: (state, { payload }) => {
      state.isGPayAvailable = payload;
    },
    setApplePayAvailableStatus: (state, { payload }) => {
      state.isApplePayAvailable = payload;
    },
    reset: () => {
      return { ...initialState };
    },
    setOneClickPaymentInvoiceId: (state, { payload }) => {
      state.oneClickInvoiceId = payload;
    },
  },
});

export const {
  set,
  toggleSend,
  selectPackage,
  reset,
  setPaymentStatus,
  setGPayAvailableStatus,
  setApplePayAvailableStatus,
  setOneClickPaymentInvoiceId,
} = paymentSlice.actions;

export default paymentSlice.reducer;
