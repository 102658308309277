export const TIME_1_MINUTE = 1;

export const RESPONSE_SUCCESS_TEXT = "OK";
export const CHECK_LIVE_STATUS_INTERVAL = 20000;

export const streamEvents = {
  connect: "connect",
  disconnect: "disconnect",
  connect_error: "connect_error",
  authorize: "em.auth",
  subscription_expire: "em.u.subscription_expire",
  stream_finished_by_publisher: "em.u.stream_finished_by_publisher",
  is_active_subscribe: "em.u.is_active_subscribe",
};

export const streamEmit = {
  is_live_profiles: "ev.u.is_live_profiles",
  is_active_publish: "ev.u.is_active_publish",
  subscription_is_active: "ev.u.subscription_is_active",
  unsubscribed: "ev.u.unsubscribed",
  subscribe_on_channel_renew: "ev.u.subscribe_on_channel_renew",
  get_agora_token: "ev.u.get_agora_token",
  subscribe_on_channel: "ev.u.subscribe_on_channel",
  renew_agora_token: "ev.u.renew_agora_token",
};

export const streamSubscribeErrors = {
  STREAM_WAS_STOPPED: 1,
  USER_NOT_AVAILABLE: 5,
  PAYMENT_SYSTEM_ERROR: 6,
  NOT_ENOUGH_CREDITS: 7,
  INTERNAL_ERROR: 8,
  ALREADY_JOINED: 9,
};

export const streamTimeExpired = {
  TIME_1_MINUTE: 1,
  TIME_10_MINUTES: 10,
};

export const streamStopReasons = {
  USER_STOPPED: 1,
  PUBLISHER_STOPPED: 2,
  SERVICE_STOPPED: 3,
};

export const streamingUserStatuses = {
  NOT_A_STREAMER: 0,
  NOT_STREAMING: 1,
  STREAMING: 2,
};
