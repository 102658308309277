import { createSlice } from "@reduxjs/toolkit";

import { MEDIA_UPLOAD_VERIFIED } from "shared/constants/media";

const initialState = {
  filesData: {},
  filesIndexAlreadySend: [],
  type: null,
  mainPhoto: null,
  send: false,
  avatar_send: {
    send: false,
  },
  openEditModal: false,
  error: {
    status: false,
    type: null,
    message: "",
  },
};

const profileFilesSlice = createSlice({
  name: "profile/files",
  initialState,
  reducers: {
    sendAvatar: (state, { payload }) => {
      state.avatar_send = payload;
    },
    setFile: (state, { payload }) => {
      const newFileList = Object.assign({}, state.filesData, {
        [payload.index]: payload,
      });
      return {
        ...state,
        error: {
          status: false,
          type: null,
          message: "",
        },
        filesData: newFileList,
      };
    },
    setError: (state, { payload }) => {
      const verifyMedia = {};
      for (const key in state.filesData) {
        if (
          Object.prototype.hasOwnProperty.call(state.filesData, key) &&
          state.filesData[key].verified === MEDIA_UPLOAD_VERIFIED
        ) {
          verifyMedia[key] = state.filesData[key];
        }
      }
      return {
        ...state,
        filesData: verifyMedia,
        error: { ...payload },
      };
    },

    reset: (state) => {
      return {
        ...state,
        ...initialState,
        type: state.error.status ?? 0,
        avatar_send: state.avatar_send.send
          ? state.avatar_send
          : { send: false },
      };
    },
    resetFiles: (state) => {
      return {
        ...state,
        filesData: {},
        filesIndexAlreadySend: [],
        avatar_send: state.avatar_send.send
          ? state.avatar_send
          : { send: false },
      };
    },
    setPhotoPreview: (state, { payload }) => {
      const updatedFile = Object.assign({}, state.filesData[payload.key], {
        previewSrc: payload.src,
      });

      return {
        ...state,
        filesData: {
          ...state.filesData,
          [payload.key]: updatedFile,
        },
      };
    },
    setMediaType: (state, { payload }) => {
      state.type = payload;
    },
    setCropData: (state, { payload }) => {
      let crop = state.filesData[payload.index].crop;

      const actionCrop = payload.crop;
      const actionPix = payload.pix;

      crop = Object.assign({}, crop, {
        x: actionCrop.x,
        y: actionCrop.y,
        width: actionCrop.width,
        height: actionCrop.height,
        rotate: payload.rotate,
      });
      const pix = Object.assign(
        {},
        {},
        {
          x: actionPix.x,
          y: actionPix.y,
          width: actionPix.width,
          height: actionPix.height,
          rotate: payload.rotate,
        }
      );

      const updatedFileCrop = Object.assign(
        {},
        state.filesData[payload.index],
        {
          crop: crop,
          pix: pix,
          verified: MEDIA_UPLOAD_VERIFIED,
        }
      );
      return {
        ...state,
        filesData: {
          ...state.filesData,
          [payload.index]: updatedFileCrop,
        },
      };
    },
  },
});

export const {
  setFile,
  setError,
  reset,
  setPhotoPreview,
  setMediaType,
  resetFiles,
  setCropData,
} = profileFilesSlice.actions;

export default profileFilesSlice.reducer;
