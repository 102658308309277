import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  init: false,
  connectionStatus: null,
  initStatus: false,
  socket: null,
  auth: null,
  user: null,
  opponent: null,
  activeChat: null,

  counters: {
    count: 0,
    countActive: 0,
    countRequests: 0,
  },
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    init: (state) => {
      state.init = true;
      state.initStatus = true;
    },
    setSocket: (state, { payload }) => {
      state.socket = payload;
      state.initStatus = false;
    },
    setAuth: (state, { payload }) => {
      state.auth = payload;
      state.connectionStatus = true;
    },
    setCounters: (state, { payload }) => {
      if (payload?.count !== state.counters.count) {
        state.counters.count = payload?.count ?? 0;
      }

      if (payload?.countActive !== state.counters.countActive) {
        state.counters.countActive = payload?.countActive ?? 0;
      }

      if (payload?.countRequests !== state.counters.countRequests) {
        state.counters.countRequests = payload?.countRequests ?? 0;
      }
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setUserChatAvatar: (state, { payload }) => {
      if (!state.user) {
        return state;
      }

      state.user = {
        ...state.user,
        avatar: payload,
      };
    },
    toggleConnectionStatus: (state, { payload }) => {
      state.connectionStatus = payload;
    },
    toggleInitStatus: (state, { payload }) => {
      state.initStatus = payload;
    },
    setActiveChat: (state, { payload }) => {
      if (state.activeChat !== payload) {
        state.activeChat = payload;
      }
    },
  },
});

export const {
  init,
  setCounters,
  setSocket,
  setAuth,
  setUser,
  toggleConnectionStatus,
  toggleInitStatus,
  setActiveChat,
  setUserChatAvatar,
} = chatSlice.actions;

export default chatSlice.reducer;
