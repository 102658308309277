import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  popular: [],
  popularSend: false,
};

const popularGiftsSlice = createSlice({
  name: "popularGifts",
  initialState,
  reducers: {
    setPopularGifts: (state, { payload }) => {
      state.popular = payload;
    },
    toggleSendPopularGifts: (state, { payload }) => {
      state.popularSend = payload;
    },
    clearPopularGifts: (state) => {
      state.popular = [];
      state.popularSend = false;
    },
  },
});
export const { setPopularGifts, clearPopularGifts, toggleSendPopularGifts } =
  popularGiftsSlice.actions;
export default popularGiftsSlice.reducer;
